import { Injectable } from '@angular/core';

import { ValidatorFn, AbstractControl, ValidationErrors, AsyncValidatorFn } from '@angular/forms';
import {
  isValidHostname,
  isValidIp4,
  isValidSubnet,
  isValidPort,
  isValidResourceName,
  isVncPasswordValid,
  isValidLauncherName,
  isValidFQDNSingleLabel,
  isValidHostnameOrIp4OrIp6,
  isValidPortRange,
  isValidCommonPathPrefix,
  isSshCredentialPasswordValid,
  isSshCredentialPrivateKeyPassphraseValid,
  isSshCredentialPrivateKeyValid,
  isValidSubdomainHostname,
} from '@app/shared/components/validation-utils';
import { Application, ApplicationServicesService } from '@agilicus/angular';
import { NotificationService } from '@app/core';
import { getApplicationServicesListByName, getNetworkPortRangesFromString } from '../application-service-state/application-services-utils';
import { catchError, map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { resolveCnameData } from '@app/shared/components/signup/signup.component';
import { getIgnoreErrorsHeader } from '../http-interceptors/http-interceptor-utils';
import { HttpClient } from '@angular/common/http';
import { DynamicEnvironmentService } from './dynamic-environment.init';

@Injectable({
  providedIn: 'root',
})
export class CustomValidatorsService {
  constructor(
    private notificationService: NotificationService,
    private applicationServicesService: ApplicationServicesService,
    private http: HttpClient,
    private env: DynamicEnvironmentService
  ) {}

  public customValidator(validatorType): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) {
        return null;
      }
      const forbidden = { forbiddenName: { value: control.value } };
      if (!validatorType(control.value.toString())) {
        return forbidden;
      }
      return null;
    };
  }

  public hostnameOrIP4Validator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) {
        return null;
      }
      const forbidden = { forbiddenName: { value: control.value } };
      if (!isValidHostname(control.value) && !isValidIp4(control.value)) {
        return forbidden;
      }
      return null;
    };
  }

  public hostnameOrIp4OrIpValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) {
        return null;
      }
      const forbidden = { forbiddenName: { value: control.value } };
      if (!isValidHostnameOrIp4OrIp6(control.value)) {
        return forbidden;
      }
      return null;
    };
  }

  public hostnameValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) {
        return null;
      }
      const forbidden = { forbiddenName: { value: control.value } };
      if (!isValidHostname(control.value)) {
        return forbidden;
      }
      return null;
    };
  }

  public subdomainHostnameValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) {
        return null;
      }
      const forbidden = { forbiddenName: { value: control.value } };
      if (!isValidSubdomainHostname(control.value)) {
        return forbidden;
      }
      return null;
    };
  }

  public portValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const valueString = control.value !== undefined && control.value !== null ? control.value.toString() : undefined;
      if (valueString === '') {
        return null;
      }
      const forbidden = { forbiddenName: { value: valueString } };
      if (valueString === undefined) {
        return forbidden;
      }
      if (!isValidPort(valueString)) {
        return forbidden;
      }
      return null;
    };
  }

  public portRangeValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const valueString = control.value !== undefined && control.value !== null ? control.value.toString() : undefined;
      if (valueString === '') {
        return null;
      }
      const portRangeArray = getNetworkPortRangesFromString(valueString);
      const forbidden = { forbiddenName: { value: valueString } };
      for (const port of portRangeArray) {
        if (port.port === undefined) {
          return forbidden;
        }
        if (!isValidPortRange(port.port)) {
          return forbidden;
        }
      }
      return null;
    };
  }

  public ip4AddressValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) {
        return null;
      }
      const forbidden = { forbiddenName: { value: control.value } };
      if (!isValidIp4(control.value)) {
        return forbidden;
      }
      return null;
    };
  }

  public subnetValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) {
        return null;
      }
      const forbidden = { forbiddenName: { value: control.value } };
      if (!isValidSubnet(control.value)) {
        return forbidden;
      }
      return null;
    };
  }

  public uniqueAppNameValidator(applications: Array<Application>, targetAppId: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) {
        return null;
      }
      const valueString = control.value.toString();
      const forbidden = { forbiddenName: { value: valueString } };
      if (!this.isUniqueAppName(valueString, applications, targetAppId)) {
        return forbidden;
      }
      return null;
    };
  }

  public positiveNumberValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) {
        return null;
      }
      const valueString = control.value.toString();
      if (valueString === '' || valueString === undefined) {
        return null;
      }
      const forbidden = { forbiddenName: { value: valueString } };
      if (valueString < 0) {
        return forbidden;
      }
      return null;
    };
  }

  public vncPasswordValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) {
        return null;
      }
      const valueString = control.value.toString();
      const forbidden = { forbiddenName: { value: valueString } };

      if (!isVncPasswordValid(valueString)) {
        this.notificationService.error('Password must be no longer than 256 characters.');
        return forbidden;
      }
      return null;
    };
  }

  private isUniqueAppName(updatedAppName: string, applications: Array<Application>, targetAppId: string): boolean {
    for (const app of applications) {
      if (app.name === updatedAppName && app.id !== targetAppId) {
        this.notificationService.error('Application with name "' + updatedAppName + '" already exists. Please provide a unique name.');
        return false;
      }
    }
    return true;
  }

  /**
   * Custom validator that checks an email and returns null if valid or an error object if invalid.
   * The email is considered valid if it is assigned to an existing user in the organisation.
   */
  public checkValidExistingEmail<KEY, VALUE>(userEmailToUserMap: Map<KEY, VALUE>): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const valueString = control.value !== undefined ? control.value.toString() : undefined;
      if (valueString === '' || valueString === undefined) {
        return null;
      }
      const forbidden = { forbiddenName: { value: control.value } };
      if (!userEmailToUserMap.has(control.value)) {
        return forbidden;
      }
      return null;
    };
  }

  public resourceNameValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) {
        return null;
      }
      const forbidden = { forbiddenName: { value: control.value } };
      if (!isValidResourceName(control.value)) {
        return forbidden;
      }
      return null;
    };
  }

  public launcherNameValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) {
        return null;
      }
      const forbidden = { forbiddenName: { value: control.value } };
      if (!isValidLauncherName(control.value)) {
        return forbidden;
      }
      return null;
    };
  }

  public fqdnSingleLabelNameValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) {
        return null;
      }
      const forbidden = { forbiddenName: { value: control.value } };
      if (!isValidFQDNSingleLabel(control.value.toLowerCase().trim())) {
        return forbidden;
      }
      return null;
    };
  }

  public isDuplicateResourceName(org_id: string, status: boolean): AsyncValidatorFn {
    return (control: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> => {
      if (status) {
        return of(null);
      }
      return getApplicationServicesListByName(this.applicationServicesService, control.value, org_id).pipe(
        map((appServiceListStateResp) => {
          if (appServiceListStateResp.length > 0) {
            this.notificationService.error('Resource with name "' + control.value + '" already exists. Please provide a unique name.');
          }
          return appServiceListStateResp.length > 0 ? { usernameAlreadyExists: true } : null;
        })
      );
    };
  }

  public cnameResolves$(domain: string, isAppCheck = false): Observable<resolveCnameData> {
    let apiDomain = 'https://api.agilicus.com';
    if (!!this.env.environment.overrideApiDomain) {
      apiDomain = this.env.environment.overrideApiDomain;
    }
    let stringToCheck = `${apiDomain}/v1/resolve/?type=CNAME&name=`;
    if (isAppCheck) {
      stringToCheck = `${stringToCheck}${domain}`;
    } else {
      stringToCheck = `${stringToCheck}auth.${domain}`;
    }
    return this.http.get(stringToCheck, { headers: getIgnoreErrorsHeader(), responseType: 'json' }).pipe(
      map((response: any) => {
        let resolveCnameData: resolveCnameData = {
          status: true,
          statusMessage: 'cnameResolved',
          response: response,
        };
        if (response.Answer.length === 0) {
          resolveCnameData.status = false;
          resolveCnameData.statusMessage = 'cnameNotResolved';
          return resolveCnameData;
        }
        return resolveCnameData;
      }),
      catchError((err) => {
        const resolveCnameData: resolveCnameData = {
          status: false,
          statusMessage: 'cnameNotResolved',
          response: undefined,
        };
        return of(resolveCnameData);
      })
    );
  }

  public async isValidCname(str: string, isAppCheck = false): Promise<boolean> {
    const res: resolveCnameData = await this.cnameResolves$(str, isAppCheck).toPromise();
    if (res.status) {
      const regex = /agilicus/i;
      for (const answer of res.response.Answer) {
        let nameCheck = `${str}.`;
        if (!isAppCheck) {
          nameCheck = `auth.${nameCheck}`;
        }
        if (answer.name === nameCheck && answer.data.match(regex) !== null) {
          return true;
        }
      }
    }
    return false;
  }

  public commonPathPrefixValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) {
        return null;
      }
      const forbidden = { forbiddenName: { value: control.value } };
      if (!isValidCommonPathPrefix(control.value)) {
        return forbidden;
      }
      return null;
    };
  }

  public sshCredentialPrivateKeyValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) {
        return null;
      }
      const valueString = control.value.toString();
      const forbidden = { forbiddenName: { value: valueString } };

      if (!isSshCredentialPrivateKeyValid(valueString)) {
        return forbidden;
      }
      return null;
    };
  }

  public sshCredentialPrivateKeyPassphraseValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) {
        return null;
      }
      const valueString = control.value.toString();
      const forbidden = { forbiddenName: { value: valueString } };

      if (!isSshCredentialPrivateKeyPassphraseValid(valueString)) {
        return forbidden;
      }
      return null;
    };
  }

  public sshCredentialPasswordValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) {
        return null;
      }
      const valueString = control.value.toString();
      const forbidden = { forbiddenName: { value: valueString } };

      if (!isSshCredentialPasswordValid(valueString)) {
        return forbidden;
      }
      return null;
    };
  }
}
