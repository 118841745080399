import {
  Application,
  ApplicationAssignment,
  ApplicationAuthenticationConfig,
  ApplicationAdditionalContext,
  ApplicationSecurity,
  ApplicationService,
  CatalogueEntry,
  CataloguesService,
  CORSSettings,
  CSPSettings,
  Environment,
  GetUserRequestParams,
  Group,
  GroupsService,
  HttpRule,
  Issuer,
  OIDCProxyConfig,
  Organisation,
  ReplaceUserRequestParams,
  RoleToRuleEntry,
  RoleV2,
  RuleScopeEnum,
  RuleSpec,
  RuleV2,
  ServiceAccount,
  User,
  UsersService,
  XSSSettings,
  ApplicationConfig,
  OIDCContentType,
  FrameOptionsSettings,
  ContentTypeOptionsSettings,
  PermittedCrossDomainPoliciesSettings,
  CrossOriginEmbedderPolicySettings,
  CrossOriginOpenerPolicySettings,
  CrossOriginResourcePolicySettings,
  ReferrerPolicySettings,
  IssuerClient,
  RuleConfig,
  HttpRuleCondition,
  RuleAction,
} from '@agilicus/angular';
import { ApplicationModel } from './application-model';
import {
  getAccessOptionValue,
  getAuthenticationOptionValue,
  getAuthorizationOptionValue,
} from '../../../shared/components/application-template-utils';
import { AuthFlowOption } from '@app/shared/components/auth-flow-option.enum';
import { UserAuthOption } from '@app/shared/components/user-auth-option.enum';
import { AccessOption } from '@app/shared/components/access-option.enum';
import { catchError, concatMap, map } from 'rxjs/operators';
import { Observable, of, throwError } from 'rxjs';
import { NotificationService } from '@app/core';
import {
  hasUserDefinedRoles,
  isAccessedOnPrem,
  isAccessedViaAgent,
  isAccessedViaVpn,
  isAuthenticatedViaProxy,
  isUsingAgilicusRuntime,
  isUsingOwnRuntime,
} from './application-model-utils';
import { RuleType } from '@app/shared/components/rule-type.enum';
import { CorsTemplateType } from '@app/shared/components/cors-template-type';
import { setAppConfigIfUnset, setCorsConfigIfUnset } from '@app/shared/components/application-configs-utils';
import { addHTTPSToUrlIfNotSet, generateRandomUuid } from '@app/shared/components/utils';
import { addCommonPathPrefixRuleToApplication, getDefaultApplicationRoleName } from '@app/core/api-applications/api-applications-utils';
import { createNewGroup$ } from '@app/core/api/group-api-utils';
import { SSHModel } from '../ssh/ssh-model';

export const ALL_ACCESS_RULE_COMMENT = 'all-access';

export interface SingleRole {
  appName: string;
  roleName: string;
}

export function makeSingleRole(appName: string, roleName: string): SingleRole {
  return {
    appName,
    roleName,
  };
}

export function needToCreateUserDefinedRoles(appModel: ApplicationModel): boolean {
  if (
    !appModel.authorization ||
    !appModel.authorization.user_defined_roles ||
    !appModel.authorization.user_defined_roles.enabled ||
    !appModel.authorization.user_defined_roles.roles ||
    appModel.authorization.user_defined_roles.roles.length === 0
  ) {
    return false;
  }
  return true;
}

export function needToCreateIssuerClient(appModel: ApplicationModel): boolean {
  const selectedAuthFlow = getAuthenticationOptionValue(appModel.authentication);
  if (selectedAuthFlow === AuthFlowOption.none) {
    return false;
  }
  return true;
}

export function needToCreateDefaultRole(appModel: ApplicationModel): boolean {
  const selectedUserAuth = getAuthorizationOptionValue(appModel.authorization);
  if (selectedUserAuth === UserAuthOption.all_org_users || selectedUserAuth === UserAuthOption.single_role_users) {
    return true;
  }
  return false;
}

export function needToUpdateGroup(appModel: ApplicationModel): boolean {
  const selectedUserAuth = getAuthorizationOptionValue(appModel.authorization);
  if (selectedUserAuth === UserAuthOption.all_org_users) {
    return true;
  }
  return false;
}

export function needToCreateServiceAccount(appModel: ApplicationModel): boolean {
  const selectedAccessOption = getAccessOptionValue(appModel.hosting);
  if (selectedAccessOption === AccessOption.internet) {
    return false;
  }
  return true;
}

export function needToCreateUpstreamService(appModel: ApplicationModel): boolean {
  return isAccessedOnPrem(appModel.hosting) && !!appModel.hosting.on_prem.upstream_services;
}

export function needToCreateAgent(appModel: ApplicationModel): boolean {
  return isAccessedViaAgent(appModel.hosting);
}

export function needToRewriteCommonMediaTypes(appModel: ApplicationModel): boolean {
  return isAccessedOnPrem(appModel.hosting) && !!appModel.hosting.on_prem.rewrite_common_media_types;
}

export function needToSetCommonPathPrefixRule(appModel: ApplicationModel): boolean {
  return !!appModel.authorization?.application_model_routing?.common_path_prefix;
}

export function needToConfigureConnector(appModel: ApplicationModel): boolean {
  return isAccessedOnPrem(appModel.hosting);
}

export function needToConfigureAgentConnector(appModel: ApplicationModel): boolean {
  return isAccessedViaAgent(appModel.hosting);
}

export function getRoleToCreate(roleName: string, app: Application, orgId: string): RoleV2 {
  const newRole: RoleV2 = {
    spec: {
      app_id: app.id,
      name: roleName,
      org_id: orgId,
    },
  };
  return newRole;
}

export function getDefaultRole(app: Application, orgId: string): RoleV2 {
  return getRoleToCreate(getDefaultApplicationRoleName(), app, orgId);
}

export function getApplicationsReaderPermissionsRole(): SingleRole {
  return makeSingleRole('urn:api:agilicus:applications', 'reader');
}

export function getRoleToRuleEntry(app: Application, orgId: string, role: RoleV2, rule: RuleV2): RoleToRuleEntry {
  const newRoleToRuleEntry: RoleToRuleEntry = {
    spec: {
      role_id: role.metadata.id,
      rule_id: rule.metadata.id,
      app_id: app.id,
      org_id: orgId,
    },
  };
  return newRoleToRuleEntry;
}

export function getDefaultEnvironmentName(): string {
  return 'primary';
}

export function getOidcProxyRuntimeCatalogueEntry(cataloguesService: CataloguesService): Observable<CatalogueEntry | undefined> {
  return cataloguesService.listAllCatalogueEntries({ catalogue_entry_name: 'identity-aware WAF proxy (v1)' }).pipe(
    map((catalogueResp) => {
      return catalogueResp.catalogue_entries[0];
    }),
    catchError((_) => {
      return of(undefined);
    })
  );
}

export function getApplicationFromModel(
  appModel: ApplicationModel,
  orgId: string,
  contactEmail: string,
  oidcProxyRuntimeCatalogueEntry?: CatalogueEntry
): Application {
  const newEnvironmentName = getDefaultEnvironmentName();
  const newEnvironment: Environment = {
    name: newEnvironmentName,
    maintenance_org_id: orgId,
  };
  setEnvironmentVersionTag(appModel, newEnvironment);
  setEnvironmentFqdnAliases(appModel, newEnvironment);
  setEnvironmentProxyLocation(appModel, newEnvironment);
  setEnvironmentApplicationConfig(newEnvironment);
  const newAssignment: ApplicationAssignment = {
    org_id: orgId,
    environment_name: newEnvironmentName,
  };
  const newApp: Application = {
    org_id: orgId,
    name: appModel.name,
    description: appModel.description,
    category: appModel.category,
    location: getApplicationLocationFromModel(appModel),
    environments: [newEnvironment],
    assignments: [newAssignment],
    contact_email: contactEmail,
    service_account_required: true,
    rules_config: {
      rules: [],
    },
    roles_config: {
      roles: [],
    },
  };
  if (isUsingOwnRuntime(appModel.hosting)) {
    newApp.image = appModel.hosting.in_cloud.runtime.own.image;
    newApp.port = appModel.hosting.in_cloud.runtime.own.port;
  } else if (!!oidcProxyRuntimeCatalogueEntry && isAccessedOnPrem(appModel.hosting)) {
    newApp.image = oidcProxyRuntimeCatalogueEntry.content;
    newApp.port = 5000;
  }
  return newApp;
}

/**
 * Will set the environments version_tag if applicable.
 */
export function setEnvironmentVersionTag(appModel: ApplicationModel, newEnvironment: Environment): void {
  if (needToSetOwnRuntimeVersionTag(appModel)) {
    newEnvironment.version_tag = appModel.hosting.in_cloud.runtime.own.version_tag;
  } else if (needToSetDefaultVersionTag(appModel)) {
    newEnvironment.version_tag = getDefaultVersionTag();
  }
}

export function setEnvironmentFqdnAliases(appModel: ApplicationModel, newEnvironment: Environment): void {
  if (!needToSetFqdnAliases(appModel)) {
    return;
  }
  newEnvironment.domain_aliases = appModel.hosting.fqdnAliases.fqdnAliasList;
}

export function setEnvironmentProxyLocation(appModel: ApplicationModel, newEnvironment: Environment): void {
  if (!isAccessedViaAgent(appModel.hosting)) {
    return;
  }
  newEnvironment.proxy_location = appModel.hosting.on_prem.agent.proxy_location;
}

export function setEnvironmentApplicationConfig(newEnvironment: Environment): void {
  newEnvironment.application_configs = getDefaultApplicationConfig();
}

export function getEmptyOidcConfig(issuer?: string): OIDCProxyConfig {
  return {
    auth: {
      auth_enabled: false,
      client_id: '',
      logout_url: '',
      issuer: !!issuer ? issuer : '',
      redirect_after_signin_path: '',
    },
    content_manipulation: {
      media_types: [],
    },
    headers: {
      domain_substitution: {
        standard_headers: {
          location: false,
          origin: false,
          host: false,
        },
        other_headers: [],
      },
      header_overrides: {
        request: {
          set: [],
          add: [],
          remove: [],
          remove_match: [],
          filters: [],
        },
        response: {
          set: [],
          add: [],
          remove: [],
          remove_match: [],
          filters: [],
        },
      },
    },
    domain_mapping: {
      primary_external_name: '',
      primary_internal_name: '',
      use_recursive_replacement_system: false,
    },
    upstream_config: {
      hostname: '',
      scheme: 'http',
    },
  };
}

export function getDefaultOidcProxyConfig(appModel: ApplicationModel, currentOrg: Organisation): OIDCProxyConfig {
  return {
    headers: {
      domain_substitution: {
        standard_headers: {
          location: false,
          origin: false,
          host: false,
        },
        other_headers: [
          {
            name: 'Destination',
            value: '',
          },
          {
            name: 'If',
            value: '',
          },
        ],
      },
    },
    domain_mapping: {
      primary_external_name: getPrimaryExternalName(appModel.name, currentOrg),
      primary_internal_name: 'localhost',
      use_recursive_replacement_system: false,
    },
    content_manipulation: {
      media_types: [],
    },
    upstream_config: {
      scheme: 'http',
      hostname: 'localhost',
    },
  };
}

export function getPrimaryExternalName(appName: string, currentOrg: Organisation): string {
  if (!currentOrg?.subdomain) {
    return '';
  }
  return `${appName}.${currentOrg.subdomain}`;
}

export function getDefaultApplicationAuthenticationConfigFromModel(appModel?: ApplicationModel): ApplicationAuthenticationConfig {
  if (!appModel) {
    return {
      application_handles_authentication: false,
    };
  }
  return {
    application_handles_authentication: isAuthenticatedViaProxy(appModel.authentication),
  };
}

export function setApplicationConfigFromModel(
  appToUpdate: Application,
  appModel: ApplicationModel,
  currentOrg: Organisation,
  currentIssuer: Issuer,
  issuerClient: IssuerClient | undefined
): void {
  setOidcProxyConfigFromAppModel(appToUpdate, appModel, currentOrg, currentIssuer, issuerClient);
  setAuthenticationConfigFromModel(appToUpdate, appModel);
  setCorsConfigFromAppModel(appToUpdate, appModel);
  setCommonPathPrefixRule(appToUpdate, appModel);
}

/**
 * Will add the OIDC proxy config to the application if applicable.
 */
export function setOidcProxyConfigFromAppModel(
  appToUpdate: Application,
  appModel: ApplicationModel,
  currentOrg: Organisation,
  currentIssuer: Issuer,
  issuerClient: IssuerClient | undefined
): void {
  if (!needToAddOidcProxyConfig(appModel)) {
    return;
  }
  const newOidcProxyConfig = getOidcProxyConfigFromAppModel(appModel, currentOrg, currentIssuer, issuerClient);
  setAppConfigIfUnset(appToUpdate.environments[0]);
  appToUpdate.environments[0].application_configs.oidc_config = newOidcProxyConfig;
}

export function getOidcProxyConfigFromAppModel(
  appModel: ApplicationModel,
  currentOrg: Organisation,
  currentIssuer: Issuer,
  issuerClient: IssuerClient | undefined
): OIDCProxyConfig {
  const newOidcProxyConfig = getDefaultOidcProxyConfig(appModel, currentOrg);
  addUpstreamServiceToOidcProxyConfig(newOidcProxyConfig, appModel, currentIssuer, issuerClient);
  setMediaTypeRewrites(newOidcProxyConfig, appModel);
  setLogoutUrl(newOidcProxyConfig, appModel);
  setRedirectAfterSigninPath(newOidcProxyConfig, appModel);
  return newOidcProxyConfig;
}

export function addUpstreamServiceToOidcProxyConfig(
  newOidcProxyConfig: OIDCProxyConfig,
  appModel: ApplicationModel,
  currentIssuer: Issuer,
  issuerClient: IssuerClient | undefined
): void {
  if (!needToCreateUpstreamService(appModel)) {
    return;
  }
  // TODO: back-end will update the application model to support multiple upstreams in the future. So we will update this later to reflect those changes
  newOidcProxyConfig.domain_mapping.primary_internal_name = appModel.hosting.on_prem.upstream_services[0].hostname;
  newOidcProxyConfig.upstream_config = {
    scheme: 'http',
    hostname: appModel.hosting.on_prem.upstream_services[0].hostname,
  };
  if (!!issuerClient) {
    // Only set the auth if an issuer client was created:
    newOidcProxyConfig.auth = {
      auth_enabled: isAuthenticatedViaProxy(appModel.authentication),
      client_id: issuerClient.name,
      issuer: currentIssuer.issuer,
    };
  }
}

export function setMediaTypeRewrites(newOidcProxyConfig: OIDCProxyConfig, appModel: ApplicationModel): void {
  if (!needToRewriteCommonMediaTypes(appModel)) {
    return;
  }
  newOidcProxyConfig.headers.domain_substitution.standard_headers = {
    host: true,
    origin: true,
    location: true,
  };
  newOidcProxyConfig.content_manipulation.media_types = getCommonMediaTypes();
}

export function setCommonPathPrefixRule(appToUpdate: Application, appModel: ApplicationModel): void {
  if (!needToSetCommonPathPrefixRule(appModel)) {
    return;
  }
  addCommonPathPrefixRuleToApplication(appModel.authorization.application_model_routing.common_path_prefix, appToUpdate);
}

export function setLogoutUrl(newOidcProxyConfig: OIDCProxyConfig, appModel: ApplicationModel): void {
  if (!isAuthenticatedViaProxy(appModel.authentication)) {
    return;
  }
  newOidcProxyConfig.auth.logout_url = appModel.authentication.proxy.logout_url;
}

export function setRedirectAfterSigninPath(newOidcProxyConfig: OIDCProxyConfig, appModel: ApplicationModel): void {
  if (!isAuthenticatedViaProxy(appModel.authentication)) {
    return;
  }
  if (!appModel.authentication.proxy.redirect_after_signin_path) {
    return;
  }
  newOidcProxyConfig.auth.redirect_after_signin_path = appModel.authentication.proxy.redirect_after_signin_path;
}

export function getCommonMediaTypes(): Array<OIDCContentType> {
  return [{ name: 'text/html' }, { name: 'application/json' }];
}

export function setAuthenticationConfigFromModel(appToUpdate: Application, appModel: ApplicationModel): void {
  setAppConfigIfUnset(appToUpdate.environments[0]);
  appToUpdate.environments[0].application_configs.authentication_config = getDefaultApplicationAuthenticationConfigFromModel(appModel);
}

/**
 * Will add the CORS config to the application if applicable.
 */
export function setCorsConfigFromAppModel(appToUpdate: Application, appModel: ApplicationModel): void {
  if (!needToAddCorsConfig(appModel)) {
    return;
  }
  setAppConfigIfUnset(appToUpdate.environments[0]);
  setCorsConfigFromTemplate(appModel.hosting.on_prem.cors.corsType, appToUpdate.environments[0]);
}

export function setCorsConfigFromTemplate(template: CorsTemplateType, environment: Environment): void {
  setCorsConfigIfUnset(environment);
  const updatedCorsSettings = getDefaultSecurityConfig().http.cors;
  if (template === CorsTemplateType.API) {
    updatedCorsSettings.enabled = true;
    updatedCorsSettings.origin_matching = CORSSettings.OriginMatchingEnum.wildcard;
    updatedCorsSettings.allow_credentials = true;
  }
  if (template === CorsTemplateType.SELF) {
    updatedCorsSettings.enabled = true;
    updatedCorsSettings.origin_matching = CORSSettings.OriginMatchingEnum.me;
  }
  environment.application_configs.security.http.cors = updatedCorsSettings;
}

export function getApplicationLocationFromModel(appModel: ApplicationModel): Application.LocationEnum {
  const selectedAccess = getAccessOptionValue(appModel.hosting);
  if (selectedAccess === AccessOption.internet) {
    return Application.LocationEnum.external;
  }
  return Application.LocationEnum.hosted;
}

export function setEnabledForNestedProperties<T extends object>(obj: T, nestedProps: Array<string>, enable: boolean): void {
  if (nestedProps.length === 0) {
    return;
  }
  const key = nestedProps.shift();
  if (!obj.hasOwnProperty(key)) {
    if (!enable) {
      return;
    }
    obj[key] = {
      enabled: enable,
    };
  } else {
    obj[key].enabled = enable;
  }
  return setEnabledForNestedProperties(obj[key], nestedProps, enable);
}

export function enableUserDefinedRoles(appModel: ApplicationModel): void {
  setEnabledForNestedProperties(appModel.authorization, ['user_defined_roles'], true);
  if (!hasUserDefinedRoles(appModel.authorization)) {
    appModel.authorization.user_defined_roles.roles = [];
  }
}

export function disableAgentHostingOption(appModel: ApplicationModel): void {
  if (!appModel || !appModel.hosting || !appModel.hosting.on_prem) {
    return;
  }
  if (!!appModel.hosting.on_prem.agent) {
    appModel.hosting.on_prem.agent.enabled = false;
  }
}

export function disableVpnHostingOption(appModel: ApplicationModel): void {
  if (!appModel || !appModel.hosting || !appModel.hosting.on_prem) {
    return;
  }
  if (!!appModel.hosting.on_prem.vpn) {
    appModel.hosting.on_prem.vpn.enabled = false;
  }
}

export function disableOnPremHostingOptions(appModel: ApplicationModel): void {
  if (!appModel || !appModel.hosting) {
    return;
  }
  setEnabledForNestedProperties(appModel.hosting, ['on_prem'], false);
  disableAgentHostingOption(appModel);
  disableVpnHostingOption(appModel);
}

export function disableCloudHostingOption(appModel: ApplicationModel): void {
  if (!appModel || !appModel.hosting) {
    return;
  }
  setEnabledForNestedProperties(appModel.hosting, ['in_cloud', 'runtime'], false);
}

export function disableProxyAuthOption(appModel: ApplicationModel): void {
  if (!appModel || !appModel.authentication) {
    return;
  }
  setEnabledForNestedProperties(appModel.authentication, ['proxy'], false);
}

export function disableSamlAuthMethod(appModel: ApplicationModel): void {
  if (!appModel || !appModel.authentication) {
    return;
  }
  setEnabledForNestedProperties(appModel.authentication, ['saml'], false);
}

export function disableOidcAuthMethod(appModel: ApplicationModel): void {
  if (!appModel || !appModel.authentication) {
    return;
  }
  setEnabledForNestedProperties(appModel.authentication, ['oidc'], false);
}

export function disableSelfAuthOptions(appModel: ApplicationModel): void {
  if (!appModel || !appModel.authentication) {
    return;
  }
  disableSamlAuthMethod(appModel);
  disableOidcAuthMethod(appModel);
}

export function createGroupWithRoles(
  newGroupName: string,
  orgId: string,
  groupService: GroupsService,
  newRoles: Array<SingleRole>
): Observable<Group | undefined> {
  const newGroup: Group = {
    first_name: newGroupName,
    roles: {},
    org_id: orgId,
  };
  newRoles.forEach((role: SingleRole) => {
    let existing = newGroup.roles[role.appName];
    if (!existing) {
      existing = [];
    }
    existing.push(role.roleName);
    newGroup.roles[role.appName] = existing;
  });
  return createNewGroup$(groupService, newGroup);
}

export function addAppModelRoleToUser(
  newRole: RoleV2,
  user: User,
  appModel: ApplicationModel,
  usersService: UsersService,
  notificationService: NotificationService
): Observable<User | undefined> {
  user.roles[appModel.name] = [newRole.spec.name];
  const replaceUserRequestParams: ReplaceUserRequestParams = {
    user_id: user.id,
    User: user,
  };
  return usersService.replaceUser(replaceUserRequestParams).pipe(
    concatMap((replaceUserResp: User) => {
      return of(replaceUserResp);
    }),
    catchError((err) => {
      notificationService.error('Failed to update the permissions of the ' + user.type + '.');
      return throwError(err);
    })
  );
}

export function getGroupAndUpdateRoles(
  newRole: RoleV2,
  currentOrg: Organisation,
  appModel: ApplicationModel,
  usersService: UsersService,
  notificationService: NotificationService
): Observable<User | undefined> {
  const getUserRequestParams: GetUserRequestParams = {
    user_id: currentOrg.all_users_group_id,
    org_id: currentOrg.id,
  };
  return usersService.getUser(getUserRequestParams).pipe(
    concatMap((getUserRequestResp: User) => {
      return addAppModelRoleToUser(newRole, getUserRequestResp, appModel, usersService, notificationService);
    })
  );
}

export function getServiceAccountName(appName: string): string {
  return `${appName}-default-sa`;
}

export function getServiceAccountFromModel(appModel: ApplicationModel, orgId: string): ServiceAccount {
  const newServiceAccount: ServiceAccount = {
    spec: {
      name: getServiceAccountName(appModel.name),
      enabled: true,
      org_id: orgId,
    },
  };
  return newServiceAccount;
}

export function getServiceTypeFromModel(appModel: ApplicationModel): ApplicationService.ServiceTypeEnum {
  if (isAccessedViaAgent(appModel.hosting)) {
    return ApplicationService.ServiceTypeEnum.agent;
  }
  if (isAccessedViaVpn(appModel.hosting)) {
    return ApplicationService.ServiceTypeEnum.vpn;
  }
  return ApplicationService.ServiceTypeEnum.internet;
}

export function getImageValueFromModel(appModel: ApplicationModel): string | undefined {
  if (isUsingOwnRuntime(appModel.hosting)) {
    return appModel.hosting.in_cloud.runtime.own.image;
  }
  if (isUsingAgilicusRuntime(appModel.hosting)) {
    return appModel.hosting.in_cloud.runtime.agilicus.image;
  }
  return undefined;
}

export function disableOwnRuntimeOption(appModel: ApplicationModel): void {
  if (!appModel || !appModel.hosting) {
    return;
  }
  setEnabledForNestedProperties(appModel.hosting.in_cloud.runtime, ['own'], false);
}

export function disableAgilicusRuntimeOption(appModel: ApplicationModel): void {
  if (!appModel || !appModel.hosting) {
    return;
  }
  setEnabledForNestedProperties(appModel.hosting.in_cloud.runtime, ['agilicus'], false);
}

export function needToAddOidcProxyConfig(appModel: ApplicationModel): boolean {
  return isAccessedOnPrem(appModel.hosting);
}

export function getDefaultVersionTag(): string {
  return 'latest';
}

export function needToSetDefaultVersionTag(appModel: ApplicationModel): boolean {
  return isAccessedOnPrem(appModel.hosting) || isUsingAgilicusRuntime(appModel.hosting);
}

export function needToSetOwnRuntimeVersionTag(appModel: ApplicationModel): boolean {
  return isUsingOwnRuntime(appModel.hosting);
}

export function ruleFromSpec(spec: RuleSpec | undefined, app: Application, orgId: string): RuleV2 {
  const newRule: RuleV2 = {
    spec: {
      app_id: app.id,
      org_id: orgId,
      ...spec,
    },
  };
  return newRule;
}

export function getApplicationUrl(appName: string, domain: string): string {
  return addHTTPSToUrlIfNotSet(`${appName}.${domain}`);
}

/**
 * Will remove leading, trailing and consecutive slashes.
 */
export function removeUnneededSlashes(str: string): string {
  return str
    .split('/')
    .filter((item) => item !== '')
    .join('/');
}

export function getApplicationUpstreamIssuerUrl(appName: string, path: string, subdomain: string): string {
  const formatedPath = removeUnneededSlashes(path);
  const formatedSubdomain = removeUnneededSlashes(subdomain);
  const appUrl = getApplicationUrl(appName, formatedSubdomain);
  return `${appUrl}/${formatedPath}`;
}

export function needToSetFqdnAliases(appModel: ApplicationModel): boolean {
  if (!appModel.hosting.fqdnAliases) {
    return false;
  }
  if (appModel.hosting.fqdnAliases.enabled && appModel.hosting.fqdnAliases.fqdnAliasList.length !== 0) {
    return true;
  }
  return false;
}

export function getNoAppIdForStatusString(): string {
  return 'noAppIdForStatus';
}

export function getAllAccessRule(app: Application, orgId: string): RuleV2 {
  const rule: RuleV2 = {
    spec: {
      app_id: app.id,
      org_id: orgId,
      condition: {
        rule_type: RuleType.HttpRule,
        methods: [HttpRule.MethodsEnum.all],
        path_regex: '/',
      },
      scope: RuleScopeEnum.assigned_to_user,
      comments: ALL_ACCESS_RULE_COMMENT,
    },
  };
  return rule;
}

export function getAllAccessPolicyRule(): RuleConfig {
  const newRuleName = generateRandomUuid();
  const httpRuleCondition: HttpRuleCondition = {
    rule_type: RuleType.HttpRule,
    methods: [HttpRule.MethodsEnum.all],
    path_regex: '/',
    condition_type: 'http_rule_condition',
  };
  const rule: RuleConfig = {
    actions: [{ action: RuleAction.ActionEnum.allow }],
    comments: ALL_ACCESS_RULE_COMMENT,
    excluded_roles: [],
    extended_condition: {
      condition: httpRuleCondition,
      negated: false,
    },
    name: newRuleName,
    priority: 0,
    roles: ['self'],
    scope: RuleScopeEnum.assigned_to_user,
  };
  return rule;
}

export function findRuleWithComment(rules: RuleV2[], comments: string): RuleV2 | undefined {
  const result = rules.filter((rule) => rule.spec.comments === comments);
  if (result.length > 0) {
    return result[0];
  }

  return undefined;
}

export function getDefaultCorsMaxAgeSecondsValue(): number {
  return 86400;
}

export function getDefaultCorsOriginMatchingValue(): CORSSettings.OriginMatchingEnum {
  return CORSSettings.OriginMatchingEnum.me;
}

export function getDefaultHstsMaxAgeSecondsValue(): number {
  return 31536000;
}

export function getDefaultCertificateTransparencyMaxAgeSecondsValue(): number {
  return 7776000;
}

export function getDefaultCspModeValue(): CSPSettings.ModeEnum {
  return CSPSettings.ModeEnum.enforce;
}

export function getDefaultXssProtectionModeValue(): XSSSettings.ModeEnum {
  return XSSSettings.ModeEnum.disabled;
}

export function getDefaultApplicationConfig(): ApplicationConfig {
  return {
    security: getDefaultSecurityConfig(),
    oidc_config: getEmptyOidcConfig(),
    additional_context: getDefaultApplicationAdditionalContext(),
    authentication_config: getDefaultApplicationAuthenticationConfigFromModel(),
  };
}

export function getDefaultApplicationAuthenticationConfig(): ApplicationAuthenticationConfig {
  return { application_handles_authentication: false };
}

export function getDefaultSecurityConfig(): ApplicationSecurity {
  return {
    http: {
      csp: {
        enabled: false,
        mode: getDefaultCspModeValue(),
        directives: [],
      },
      cors: {
        enabled: false,
        origin_matching: getDefaultCorsOriginMatchingValue(),
        allow_origins: [],
        allow_methods: [],
        allow_headers: [],
        expose_headers: [],
        max_age_seconds: getDefaultCorsMaxAgeSecondsValue(),
        allow_credentials: false,
      },
      hsts: {
        enabled: false,
        max_age_seconds: getDefaultHstsMaxAgeSecondsValue(),
        include_sub_domains: false,
        preload: false,
      },
      xss_protection: {
        enabled: false,
        mode: getDefaultXssProtectionModeValue(),
        report_uri: '',
      },
      certificate_transparency: {
        enabled: false,
        report_uri: '',
        enforce: false,
        max_age_seconds: getDefaultCertificateTransparencyMaxAgeSecondsValue(),
      },
      frame_options: {
        enabled: false,
        mode: FrameOptionsSettings.ModeEnum.clear,
      },
      content_type_options: {
        enabled: false,
        mode: ContentTypeOptionsSettings.ModeEnum.clear,
      },
      permitted_cross_domain_policies: {
        enabled: false,
        mode: PermittedCrossDomainPoliciesSettings.ModeEnum.clear,
      },
      coep: {
        enabled: false,
        mode: CrossOriginEmbedderPolicySettings.ModeEnum.clear,
      },
      coop: {
        enabled: false,
        mode: CrossOriginOpenerPolicySettings.ModeEnum.clear,
      },
      corp: {
        enabled: false,
        mode: CrossOriginResourcePolicySettings.ModeEnum.clear,
      },
      referrer_policy: {
        enabled: false,
        mode: ReferrerPolicySettings.ModeEnum.clear,
      },
    },
  };
}

export function getDefaultApplicationAdditionalContext(): ApplicationAdditionalContext {
  return { include_user_context_headers: false };
}

export function needToAddCorsConfig(appModel: ApplicationModel): boolean {
  return (
    !!appModel?.hosting?.on_prem?.cors?.enabled &&
    !!appModel?.hosting?.on_prem?.cors?.corsType &&
    appModel.hosting.on_prem.cors.corsType !== CorsTemplateType.NONE
  );
}

export function needToCreateSshCredential(model: SSHModel): boolean {
  return !!model.secrets?.private_key || !!model.secrets?.private_key_passphrase || !!model.secrets?.password;
}
