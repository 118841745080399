import { Component, ChangeDetectionStrategy, Input, OnChanges, ViewChild, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { AppState, NotificationService } from '@app/core';
import { ActionApiApplicationsUpdateApplicationModel } from '@app/core/api-applications/api-applications.actions';
import { Store } from '@ngrx/store';
import { ApplicationModel } from '@app/core/models/application/application-model';
import { AuthFlowOption } from '../auth-flow-option.enum';
import { AuthMethodOption } from '../auth-method-option.enum';
import { RuntimeOption } from '../runtime-option.enum';
import { uploadDataFromTextFile } from '../file-utils';
import { AccessOption } from '../access-option.enum';
import { KeyTabManager } from '../key-tab-manager/key-tab-manager';
import { UserAuthOption } from '../user-auth-option.enum';
import {
  canConfigureAuthUsers,
  canConfigureRolesAndRules,
  getAuthenticationMethodOptionValue,
  getAuthenticationOptionValue,
  getAuthMethodOptionData,
  getRuntimeOptionData,
  getUserAuthOptionData,
} from '../application-template-utils';
import { modifyDataOnFormBlur } from '../utils';
import { ApplicationStepperController } from '../application-stepper/application-stepper-controller';

@Component({
  selector: 'portal-application-auth-flow-stepper',
  templateUrl: './application-auth-flow-stepper.component.html',
  styleUrls: ['./application-auth-flow-stepper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ApplicationAuthFlowStepperComponent implements OnChanges {
  @Input() public currentApplicationModel: ApplicationModel;
  @Input() public authFlowFormGroup: UntypedFormGroup;
  // Workaround for angular stepper bug:
  // See: https://github.com/angular/components/issues/20923
  @Input() public authFlowOptionChanged = false;
  @Output() public resetAuthFlowOptionChanged = new EventEmitter<any>();
  public runtimeOptionData = getRuntimeOptionData();
  public authMethodOptionData = getAuthMethodOptionData();
  public userAuthOptionData = getUserAuthOptionData();
  private validSAMLMetadataFileType = 'xml';

  // This is required in order to reference the enums in the html template.
  public accessOption = AccessOption;
  public userAuthOption = UserAuthOption;
  public runtimeOption = RuntimeOption;
  public authMethodOption = AuthMethodOption;
  public authFlowOption = AuthFlowOption;

  // For setting enter key to change input focus.
  public keyTabManager: KeyTabManager = new KeyTabManager();

  public canConfigureAuthUsers = canConfigureAuthUsers;
  public canConfigureRolesAndRules = canConfigureRolesAndRules;
  public getAuthenticationMethodOptionValue = getAuthenticationMethodOptionValue;
  public getAuthenticationOptionValue = getAuthenticationOptionValue;

  public appStepperController: ApplicationStepperController = new ApplicationStepperController();

  @ViewChild('authFlowStepper') public stepper: MatStepper;

  constructor(private store: Store<AppState>, private notificationService: NotificationService) {}

  public ngOnChanges(): void {
    // Workaround for angular stepper bug:
    // See: https://github.com/angular/components/issues/20923
    if (this.authFlowOptionChanged && !!this.stepper && !!this.stepper.steps && this.stepper.steps.length > 0) {
      this.stepper.selectedIndex = 0;
      this.resetAuthFlowOptionChangedEvent();
    }
  }

  public onAuthMethodChange(authMethodOption: AuthMethodOption): void {
    if (authMethodOption === AuthMethodOption.saml) {
      this.appStepperController.handleSamlAuthMethodSelection(this.currentApplicationModel);
    }
    if (authMethodOption === AuthMethodOption.oidc) {
      this.appStepperController.handleOidcAuthMethodSelection(this.currentApplicationModel);
    }
    this.updateApplicationModel();
  }

  public onUserAuthChange(userAuthOption: UserAuthOption): void {
    if (userAuthOption === UserAuthOption.all_users) {
      this.appStepperController.handleAllUsersUserAuthSelection(this.currentApplicationModel);
    }
    if (userAuthOption === UserAuthOption.all_org_users) {
      this.appStepperController.handleAllOrgUsersUserAuthSelection(this.currentApplicationModel);
    }
    if (userAuthOption === UserAuthOption.single_role_users) {
      this.appStepperController.handleSingleRoleUsersUserAuthSelection(this.currentApplicationModel);
    }
    if (userAuthOption === UserAuthOption.distinct_role_users) {
      this.appStepperController.handleDistinctRoleUsersUserAuthSelection(this.currentApplicationModel);
    }
    if (userAuthOption === UserAuthOption.all_org_users_plus_roles) {
      this.appStepperController.handleAllOrgUsersPlusRolesUserAuthSelection(this.currentApplicationModel);
    }
    this.updateApplicationModel();
  }

  private updateApplicationModel(): void {
    this.store.dispatch(new ActionApiApplicationsUpdateApplicationModel(this.currentApplicationModel));
  }

  private resetAuthFlowOptionChangedEvent(): void {
    this.resetAuthFlowOptionChanged.emit();
  }

  public clearMetadata(): void {
    this.currentApplicationModel.authentication.saml.saml_metadata_file = '';
    this.authFlowFormGroup.value.saml_metadata_file = '';
    this.updateApplicationModel();
  }

  public uploadMetadata(event: any): void {
    uploadDataFromTextFile(event, this.notificationService, this.onReadSAMLFile.bind(this), this.validSAMLMetadataFileType);
  }

  public onReadSAMLFile(reader: FileReader): void {
    this.currentApplicationModel.authentication.saml.saml_metadata_file = reader.result.toString();
    this.updateApplicationModel();
  }

  public onFormBlur<T extends object>(form: UntypedFormGroup, formField: string, obj: T): void {
    modifyDataOnFormBlur(form, formField, this.modifyAppStepperDataOnFormBlur.bind(this), obj);
  }

  private modifyAppStepperDataOnFormBlur<T extends object>(form: UntypedFormGroup, formField: string, obj: T): void {
    obj[formField] = form.get(formField).value;
    this.updateApplicationModel();
  }
}
