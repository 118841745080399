import {
  ApplicationServicesService,
  ApplicationService,
  ApplicationServiceAssignment,
  Application,
  patch_via_put,
  FileShareService,
  ListFileShareServicesRequestParams,
  GetApplicationServiceRequestParams,
  ServiceForwarder,
  ListServiceForwardersRequestParams,
  DesktopResource,
  SSHResource,
  ListDesktopResourcesRequestParams,
  ListSshResourcesRequestParams,
  ListApplicationServicesRequestParams,
  GetFileShareServiceRequestParams,
  GetDesktopResourceRequestParams,
  DesktopResourceSpec,
  GetSshResourceRequestParams,
  DesktopServerConfiguration,
  NetworkPortRange,
} from '@agilicus/angular';
import { Observable, of, throwError } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';
import { ApplicationModel, UpstreamService } from '../models/application/application-model';
import { getServiceTypeFromModel } from '../models/application/application-model-api-utils';
import { isAccessedViaVpn } from '../models/application/application-model-utils';
import { DesktopModel } from '../models/desktop/desktop-model';
import { SSHModel } from '../models/ssh/ssh-model';
import { FileShareModel } from '../models/file-share/file-share-model';
import { NetworkModel } from '../models/network/network-model';
import { getExistingData, getMoreThanOneResultError } from '../api/api-utils';
import { NetworkElement } from '@app/shared/components/network-overview/network-overview.component';

export function getApplicationService(
  applicationServicesService: ApplicationServicesService,
  applicationServiceId: string,
  orgId: string
): Observable<ApplicationService | undefined> {
  const getApplicationServiceRequestParams: GetApplicationServiceRequestParams = {
    app_service_id: applicationServiceId,
    org_id: orgId,
  };
  return applicationServicesService.getApplicationService(getApplicationServiceRequestParams);
}

export function getApplicationServicesList(
  applicationServicesService: ApplicationServicesService,
  orgId: string
): Observable<Array<ApplicationService> | undefined> {
  const listApplicationServicesRequestParams: ListApplicationServicesRequestParams = {
    org_id: orgId,
    show_status: true,
  };
  return applicationServicesService.listApplicationServices(listApplicationServicesRequestParams).pipe(
    map((resp) => {
      return resp.application_services;
    })
  );
}

export function getApplicationServicesListByName(
  applicationServicesService: ApplicationServicesService,
  name: string,
  orgId: string
): Observable<Array<ApplicationService> | undefined> {
  const listApplicationServicesRequestParams: ListApplicationServicesRequestParams = {
    org_id: orgId,
    name: name,
  };
  return applicationServicesService.listApplicationServices(listApplicationServicesRequestParams).pipe(
    map((resp) => {
      return resp.application_services;
    })
  );
}

export function getApplicationServiceByName$(
  applicationServicesService: ApplicationServicesService,
  name: string,
  orgId: string
): Observable<ApplicationService | undefined> {
  const listApplicationServicesRequestParams: ListApplicationServicesRequestParams = {
    org_id: orgId,
    name: name,
  };
  return applicationServicesService.listApplicationServices(listApplicationServicesRequestParams).pipe(
    concatMap((resp) => {
      const moreThanOneResultError = getMoreThanOneResultError(resp.application_services);
      if (!!moreThanOneResultError) {
        return throwError(() => moreThanOneResultError);
      }
      return of(resp.application_services.length === 0 ? undefined : resp.application_services[0]);
    })
  );
}

export function createNewApplicationService(
  applicationServicesService: ApplicationServicesService,
  newApplicationService: ApplicationService
): Observable<ApplicationService | undefined> {
  return applicationServicesService.createApplicationService({
    ApplicationService: newApplicationService,
  });
}

export function createNewApplicationServiceAndUpdateIfAlreadyExists$(
  applicationServicesService: ApplicationServicesService,
  newApplicationService: ApplicationService
): Observable<ApplicationService | undefined> {
  return createNewApplicationService(applicationServicesService, newApplicationService).pipe(
    catchError((err) => {
      const existingData: ApplicationService = getExistingData(err);
      if (!existingData) {
        return throwError(err);
      }
      for (const key of Object.keys(newApplicationService)) {
        existingData[key] = newApplicationService[key];
      }
      return updateExistingApplicationService(applicationServicesService, existingData);
    })
  );
}

export function deleteExistingApplicationService(
  applicationServicesService: ApplicationServicesService,
  appServiceId: string,
  orgId: string
): Observable<void> {
  return applicationServicesService.deleteApplicationService({
    app_service_id: appServiceId,
    org_id: orgId,
  });
}

export function deleteExistingApplicationServiceByName$(
  applicationServicesService: ApplicationServicesService,
  name: string,
  orgId: string
): Observable<ApplicationService> {
  return getApplicationServiceByName$(applicationServicesService, name, orgId).pipe(
    concatMap((getResp) => {
      if (!getResp) {
        return of(undefined);
      }
      return deleteExistingApplicationService(applicationServicesService, getResp.id, orgId).pipe(map((deleteResp) => getResp));
    })
  );
}

export function updateExistingApplicationService(
  applicationServicesService: ApplicationServicesService,
  applicationServiceToUpdate: ApplicationService
): Observable<ApplicationService | undefined> {
  const putter = (appService: ApplicationService) => {
    return applicationServicesService.replaceApplicationService({
      app_service_id: appService.id,
      ApplicationService: appService,
    });
  };
  const getter = (appService: ApplicationService) => {
    return applicationServicesService.getApplicationService({
      app_service_id: appService.id,
      org_id: appService.org_id,
    });
  };
  return patch_via_put(applicationServiceToUpdate, getter, putter);
}

export function getSelfHostedUpstreamServiceName(appModel: ApplicationModel, upstreamServiceIndex: number): string {
  return appModel.name + '-local-service-' + upstreamServiceIndex;
}

export function getSelfHostedApplicationServiceFromApplicationModel(
  appModel: ApplicationModel,
  orgId: string,
  newApp: Application,
  connectorId: string | undefined,
  upstreamService: UpstreamService,
  upstreamServiceIndex: number
): ApplicationService | undefined {
  const newApplicationServiceAssignment: ApplicationServiceAssignment = {
    app_id: newApp.id,
    environment_name: newApp.environments[0].name,
    org_id: orgId,
    expose_type: upstreamService.expose_type,
  };
  if (upstreamService.hostname_alias) {
    newApplicationServiceAssignment.expose_as_hostnames = [upstreamService.hostname_alias];
  }
  const newApplicationService: ApplicationService = {
    name: getSelfHostedUpstreamServiceName(appModel, upstreamServiceIndex),
    org_id: orgId,
    hostname: upstreamService.hostname,
    port: Number(upstreamService.port),
    assignments: [newApplicationServiceAssignment],
    service_type: getServiceTypeFromModel(appModel),
    tls_enabled: !!upstreamService.tls_enabled ? true : false,
    tls_verify: isTlsVerified(upstreamService),
  };
  if (newApplicationServiceAssignment.expose_type === 'application') {
    newApplicationService.protocol_config = upstreamService.protocol_config;
  }
  if (!!connectorId) {
    newApplicationService.connector_id = connectorId;
  }
  if (!!isAccessedViaVpn(appModel.hosting)) {
    newApplicationService.ipv4_addresses = [upstreamService.ip_address];
  }
  return newApplicationService;
}

export function isTlsVerified(upstreamService: UpstreamService): boolean {
  if (!upstreamService.tls_enabled) {
    return false;
  }
  return !!upstreamService.tls_verify ? true : false;
}

export function getFileShareServiceFromModel(model: FileShareModel, orgId: string, connectorId: string | undefined): FileShareService {
  if (!connectorId) {
    return undefined;
  }

  const newFileShareService: FileShareService = {
    spec: {
      share_name: model.share_name,
      name: model.name,
      org_id: orgId,
      local_path: model.local_path,
      connector_id: connectorId,
      // back-end will set this value, so set to undefined:
      transport_end_to_end_tls: undefined,
    },
  };
  return newFileShareService;
}

export function createFileShareService(
  applicationServicesService: ApplicationServicesService,
  FileShareService: FileShareService
): Observable<FileShareService | undefined> {
  return applicationServicesService.createFileShareService({
    FileShareService: FileShareService,
  });
}

export function updateExistingFileShareService(
  applicationServicesService: ApplicationServicesService,
  fileShareServiceToUpdate: FileShareService
): Observable<FileShareService | undefined> {
  const putter = (fileShareService: FileShareService) => {
    return applicationServicesService.replaceFileShareService({
      file_share_service_id: fileShareService.metadata.id,
      FileShareService: fileShareService,
    });
  };
  const getter = (fileShareService: FileShareService) => {
    return applicationServicesService.getFileShareService({
      file_share_service_id: fileShareService.metadata.id,
      org_id: fileShareService.spec.org_id,
    });
  };
  return patch_via_put(fileShareServiceToUpdate, getter, putter);
}

export function getFileShareServices(
  applicationServicesService: ApplicationServicesService,
  orgId: string | undefined
): Observable<Array<FileShareService>> {
  let fileShareServices$: Observable<Array<FileShareService>> = of([]);
  if (!!orgId) {
    const listFileShareServiceRequestParams: ListFileShareServicesRequestParams = {
      org_id: orgId,
    };
    fileShareServices$ = applicationServicesService.listFileShareServices(listFileShareServiceRequestParams).pipe(
      map((resp) => {
        return resp.file_share_services;
      }),
      catchError((_) => {
        return of([]);
      })
    );
  }
  return fileShareServices$;
}

export function getFileShareServicesById(
  applicationServicesService: ApplicationServicesService,
  id: string,
  orgId: string | undefined
): Observable<FileShareService> {
  const getFileShareServiceRequestParams: GetFileShareServiceRequestParams = {
    file_share_service_id: id,
    org_id: orgId,
  };
  return applicationServicesService.getFileShareService(getFileShareServiceRequestParams);
}

export function getFileShareServiceByName$(
  applicationServicesService: ApplicationServicesService,
  name: string,
  orgId: string | undefined
): Observable<FileShareService | undefined> {
  const listFileShareServicesRequestParams: ListFileShareServicesRequestParams = {
    org_id: orgId,
    name: name,
  };
  return applicationServicesService.listFileShareServices(listFileShareServicesRequestParams).pipe(
    concatMap((resp) => {
      const moreThanOneResultError = getMoreThanOneResultError(resp.file_share_services);
      if (!!moreThanOneResultError) {
        return throwError(() => moreThanOneResultError);
      }
      return of(resp.file_share_services.length === 0 ? undefined : resp.file_share_services[0]);
    })
  );
}

export function getFileShareServicesList(
  applicationServicesService: ApplicationServicesService,
  orgId: string | undefined
): Observable<Array<FileShareService>> {
  const ListFileShareServicesRequestParams: ListFileShareServicesRequestParams = {
    org_id: orgId,
  };
  return applicationServicesService.listFileShareServices(ListFileShareServicesRequestParams).pipe(
    map((resp) => {
      return resp.file_share_services;
    })
  );
}

export function deleteExistingFileShareService(
  applicationServicesService: ApplicationServicesService,
  id: string,
  orgId: string
): Observable<void> {
  return applicationServicesService.deleteFileShareService({
    file_share_service_id: id,
    org_id: orgId,
  });
}

export function deleteExistingFileShareServiceByName$(
  applicationServicesService: ApplicationServicesService,
  name: string,
  orgId: string
): Observable<FileShareService> {
  return getFileShareServiceByName$(applicationServicesService, name, orgId).pipe(
    concatMap((getResp) => {
      if (!getResp) {
        return of(undefined);
      }
      return deleteExistingFileShareService(applicationServicesService, getResp.metadata.id, orgId).pipe(map((deleteResp) => getResp));
    })
  );
}

export function getServiceForwarders(
  applicationServicesService: ApplicationServicesService,
  orgId: string
): Observable<Array<ServiceForwarder>> {
  const listServiceForwardersRequestParams: ListServiceForwardersRequestParams = {
    org_id: orgId,
  };
  return applicationServicesService.listServiceForwarders(listServiceForwardersRequestParams).pipe(
    map((resp) => {
      return resp.service_forwarders;
    })
  );
}

export function createNewServiceForwarder(
  applicationServicesService: ApplicationServicesService,
  newServiceForwarder: ServiceForwarder
): Observable<ServiceForwarder | undefined> {
  return applicationServicesService.createServiceForwarder({
    ServiceForwarder: newServiceForwarder,
  });
}

export function updateExistingServiceForwarder(
  applicationServicesService: ApplicationServicesService,
  serviceForwarderToUpdate: ServiceForwarder
): Observable<ServiceForwarder | undefined> {
  // Workaround until issue is addressed in the sdk.
  delete serviceForwarderToUpdate.status;
  delete serviceForwarderToUpdate._builtin_original?.status;
  const putter = (serviceForwarder: ServiceForwarder) => {
    return applicationServicesService.replaceServiceForwarder({
      service_forwarder_id: serviceForwarder.metadata.id,
      ServiceForwarder: serviceForwarder,
    });
  };
  const getter = (serviceForwarder: ServiceForwarder) => {
    return applicationServicesService
      .getServiceForwarder({
        service_forwarder_id: serviceForwarder.metadata.id,
        org_id: serviceForwarder.spec.org_id,
      })
      .pipe(
        map((getResp) => {
          // Workaround until issue is addressed in the sdk.
          delete getResp.status;
          delete getResp._builtin_original?.status;
          return getResp;
        })
      );
  };
  return patch_via_put(serviceForwarderToUpdate, getter, putter);
}

export function getDesktopResourceFromModel(
  model: DesktopModel,
  orgId: string,
  connectorId: string | undefined
): DesktopResource | undefined {
  if (!connectorId) {
    return undefined;
  }

  const newDesktopResource: DesktopResource = {
    spec: {
      name: model.name,
      address: model.address,
      desktop_type: model.desktop_type,
      org_id: orgId,
      connector_id: connectorId,
      connection_info: model.connection_info,
      config: model.config,
      allow_non_domain_joined_users: !!model.allow_non_domain_joined_users,
    },
  };
  newDesktopResource.spec.config.dynamic_source_port_override = true;
  if (model.desktop_type === DesktopResourceSpec.DesktopTypeEnum.rdp) {
    if (!!model.remote_app?.command_path) {
      newDesktopResource.spec.remote_app = {
        command_path: model.remote_app.command_path,
      };
    }
    if (!!model.remote_app?.command_arguments) {
      newDesktopResource.spec.remote_app.command_arguments = model.remote_app.command_arguments;
    }
    if (!!model.remote_app?.working_directory) {
      newDesktopResource.spec.remote_app.working_directory = model.remote_app.working_directory;
    }
  }
  return newDesktopResource;
}

export function createNewDesktopResource(
  applicationServicesService: ApplicationServicesService,
  newDesktopResource: DesktopResource
): Observable<DesktopResource | undefined> {
  return applicationServicesService.createDesktopResource({
    DesktopResource: newDesktopResource,
  });
}

export function getDesktopResourceById(
  applicationServicesService: ApplicationServicesService,
  id: string,
  orgId: string | undefined
): Observable<DesktopResource | undefined> {
  const getDesktopRequestParams: GetDesktopResourceRequestParams = {
    resource_id: id,
    org_id: orgId,
  };
  return applicationServicesService.getDesktopResource(getDesktopRequestParams);
}

export function getDesktopResourceByName$(
  applicationServicesService: ApplicationServicesService,
  name: string,
  orgId: string | undefined
): Observable<DesktopResource | undefined> {
  const listDesktopResourcesRequestParams: ListDesktopResourcesRequestParams = {
    org_id: orgId,
    name: name,
  };
  return applicationServicesService.listDesktopResources(listDesktopResourcesRequestParams).pipe(
    concatMap((resp) => {
      const moreThanOneResultError = getMoreThanOneResultError(resp.desktop_resources);
      if (!!moreThanOneResultError) {
        return throwError(() => moreThanOneResultError);
      }
      return of(resp.desktop_resources.length === 0 ? undefined : resp.desktop_resources[0]);
    })
  );
}

export function getDesktopResourcesList(
  applicationServicesService: ApplicationServicesService,
  orgId: string | undefined
): Observable<Array<DesktopResource>> {
  const listDesktopResourceRequestParams: ListDesktopResourcesRequestParams = {
    org_id: orgId,
  };
  return applicationServicesService.listDesktopResources(listDesktopResourceRequestParams).pipe(
    map((resp) => {
      return resp.desktop_resources;
    })
  );
}

export function deleteExistingDesktopResource(
  applicationServicesService: ApplicationServicesService,
  id: string,
  orgId: string
): Observable<any> {
  return applicationServicesService.deleteDesktopResource({
    resource_id: id,
    org_id: orgId,
  });
}

export function deleteExistingDesktopResourceByName$(
  applicationServicesService: ApplicationServicesService,
  name: string,
  orgId: string
): Observable<DesktopResource> {
  return getDesktopResourceByName$(applicationServicesService, name, orgId).pipe(
    concatMap((getResp) => {
      if (!getResp) {
        return of(undefined);
      }
      return deleteExistingDesktopResource(applicationServicesService, getResp.metadata.id, orgId).pipe(map((deleteResp) => getResp));
    })
  );
}

export function updateExistingDesktopResource(
  applicationServicesService: ApplicationServicesService,
  desktopResourceToUpdate: DesktopResource
): Observable<DesktopResource | undefined> {
  const putter = (desktopResource: DesktopResource) => {
    return applicationServicesService.replaceDesktopResource({
      resource_id: desktopResource.metadata.id,
      DesktopResource: desktopResource,
    });
  };
  const getter = (desktopResource: DesktopResource) => {
    return applicationServicesService.getDesktopResource({
      resource_id: desktopResource.metadata.id,
      org_id: desktopResource.spec.org_id,
    });
  };
  return patch_via_put(desktopResourceToUpdate, getter, putter);
}

export function getDesktopResources(
  applicationServicesService: ApplicationServicesService,
  orgId: string | undefined
): Observable<Array<DesktopResource>> {
  let desktopResource$: Observable<Array<DesktopResource>> = of([]);
  if (!!orgId) {
    const listDesktopResourcesRequestParams: ListDesktopResourcesRequestParams = {
      org_id: orgId,
    };
    desktopResource$ = applicationServicesService.listDesktopResources(listDesktopResourcesRequestParams).pipe(
      map((resp) => {
        return resp.desktop_resources;
      }),
      catchError((_) => {
        return of([]);
      })
    );
  }
  return desktopResource$;
}

export function getDesktopServerConfiguration(
  applicationServicesService: ApplicationServicesService,
  orgId: string | undefined,
  desktopId: string
): Observable<DesktopServerConfiguration> {
  return applicationServicesService.createServerConfiguration({
    resource_id: desktopId,
    DesktopServerConfiguration: {
      org_id: orgId,
      configuration_file_format: 'win-reg',
    },
  });
}

export function getSSHResourceFromModel(model: SSHModel, orgId: string, connectorId: string | undefined): SSHResource | undefined {
  if (!connectorId) {
    return undefined;
  }

  const newSSHResource: SSHResource = {
    spec: {
      name: model.name,
      address: model.address,
      org_id: orgId,
      connector_id: connectorId,
      config: model.config,
    },
  };
  if (!!model.username) {
    newSSHResource.spec.username = model.username;
  }
  return newSSHResource;
}

export function getNetworkFromModel(model: NetworkModel, orgId: string, connectorId: string | undefined): ApplicationService | undefined {
  if (!connectorId) {
    return undefined;
  }

  const newobject: ApplicationService = {
    name: model.name,
    hostname: model.hostname,
    org_id: orgId,
    connector_id: connectorId,
    config: model.config,
    tls_enabled: model.tls_enabled,
    tls_verify: model.tls_verify,
  };
  if (!!model.override_ip) {
    newobject.ipv4_addresses = [model.override_ip];
  }
  if (!!model.expose_as_hostname) {
    newobject.protocol_config = { expose_config: { expose_as_hostname: model.expose_as_hostname } };
  }
  if (!!model.source_port_override) {
    newobject.config.source_port_override = getNetworkPortRangesFromString(model.source_port_override);
  }
  newobject.config.source_address_override = model.source_address_override;
  return newobject;
}

export function createNewSSHResource(
  applicationServicesService: ApplicationServicesService,
  newSSHResource: SSHResource
): Observable<SSHResource | undefined> {
  return applicationServicesService.createSshResource({
    SSHResource: newSSHResource,
  });
}

export function getSSHResourceById(
  applicationServicesService: ApplicationServicesService,
  id: string,
  orgId: string | undefined
): Observable<SSHResource | undefined> {
  const getSshRequestParams: GetSshResourceRequestParams = {
    resource_id: id,
    org_id: orgId,
  };
  return applicationServicesService.getSshResource(getSshRequestParams);
}

export function getSSHResourcesList(
  applicationServicesService: ApplicationServicesService,
  orgId: string | undefined
): Observable<Array<SSHResource>> {
  const listSshResourceRequestParams: ListSshResourcesRequestParams = {
    org_id: orgId,
  };
  return applicationServicesService.listSshResources(listSshResourceRequestParams).pipe(
    map((resp) => {
      return resp.ssh_resources;
    })
  );
}

export function deleteExistingSSHResource(
  applicationServicesService: ApplicationServicesService,
  id: string,
  orgId: string
): Observable<void> {
  return applicationServicesService.deleteSshResource({
    resource_id: id,
    org_id: orgId,
  });
}

export function updateExistingSSHResource(
  applicationServicesService: ApplicationServicesService,
  sshResourceToUpdate: SSHResource
): Observable<SSHResource | undefined> {
  const putter = (sshResource: SSHResource) => {
    return applicationServicesService.replaceSshResource({
      resource_id: sshResource.metadata.id,
      SSHResource: sshResource,
    });
  };
  const getter = (sshResource: SSHResource) => {
    return applicationServicesService.getSshResource({
      resource_id: sshResource.metadata.id,
      org_id: sshResource.spec.org_id,
    });
  };
  return patch_via_put(sshResourceToUpdate, getter, putter);
}

export function getRemoteDesktopApplicationCommandPathTooltipText(): string {
  return `This is the full path and name to the remote application's executable on the desktop. For example "C:\Windows\System32\notepad.exe".`;
}

export function getNetworkDescriptiveText(): string {
  return `A 'Network' is a global resource (usually TCP) available from your domain of control to web applications running in the platform. 
    This might be e.g. databases, internal remote desktop services. The workload firewall gates access to these.`;
}

export function getNetworkNameTooltipText(): string {
  return `This is the name by which external users will access this.`;
}

export function getNetworkHostnameTooltipText(): string {
  return `This is the hostname or IP address as inside your infrastructure. It may be the same as the network name.`;
}

export function getNetworkPortTooltipText(): string {
  return `The transport-layer port on which to access the network. 
  This can be either a single port within the range 0-65535 (max port) or a range of ports in a comma-separated list, eg. "8080, 9000-9050, 10000-100020".`;
}

export function getNetworkSourcePortOverrideTooltipText(): string {
  return `When proxying, the source port the launcher listens on will default to the destination port. 
  The source port override allows the listener port to be changed, in case there is a collision. 
  This can be either a single port within the range 0-65535 (max port) or a range of ports in a comma-separated list, eg. "8080, 9000-9050, 10000-100020".
  Alternatively, the system can dynamically allocate a port if dynamic source port override is specified.`;
}

export function getNetworkSourceAddressOverrideTooltipText(): string {
  return `When proxying, the source address the launcher listens on will default to either
  localhost (no-interceptor), or, a dynamic IP in 127.x.x.x range (interceptor).
  This can be overridden and forced, above. Note the machine running the launcher
  must be able to bind to this address.`;
}

export function getNetworkOverrideIpTooltipText(): string {
  return `This is normally unused. Use if hostname does not resolve properly in your DNS.`;
}

export function getNetworkTlsVerifyTooltipText(): string {
  return `Verify the certificate of this network (requires hostname to be valid)`;
}

export function getNetworkExposeAsHostnameTooltipText(): string {
  return `Exposes the network as a TLS endpoint. Warning, this is similar to a port forward from a security standpoint.`;
}

export function getDisableHttp2CheckboxTooltip(): string {
  return `In rare circumstances an application may misbehave on HTTP/2. This option will remove the ALPN option from the TLS handshake.`;
}

export function getDynamicSourcePortOverrideTooltipText(): string {
  return `Whether or not to have the system dynamically allocate the listener port, in case there is a collision.`;
}

export function getNetworkPortRangesFromString(portRangesString: string): Array<NetworkPortRange> {
  if (!portRangesString) {
    return [];
  }
  const portRangesStringArray = portRangesString.split(',').map((str) => str.trim());
  const portRanges: Array<NetworkPortRange> = [];
  for (const portString of portRangesStringArray) {
    const networkPortRange: NetworkPortRange = {
      protocol: NetworkPortRange.ProtocolEnum.tcp,
      port: portString,
    };
    portRanges.push(networkPortRange);
  }
  return portRanges;
}

export function getPortRangeLimit(): number {
  return 10;
}

export function setNetworkConfigIfNotSet(network: NetworkElement): void {
  if (!network.config) {
    network.config = {
      ports: [],
      source_port_override: [],
      source_address_override: undefined,
      dynamic_source_port_override: false,
    };
  }
}

export function setNetworkFromNetworkElement(network: NetworkElement): void {
  setNetworkConfigIfNotSet(network);
  network.config.ports = getNetworkPortRangesFromString(network.ports);
  network.config.source_port_override = getNetworkPortRangesFromString(network.source_port_override);
  network.config.source_address_override = network.source_address_override;
  if (!!network.override_ip) {
    network.ipv4_addresses = [network.override_ip];
  } else {
    network.ipv4_addresses = [];
  }
}
