<ng-container *ngIf="(combinedSignupData$ | async) as state">
  <div class="sign-up">
    <portal-page-info
      pageTitle="New Customer Sign Up"
      [productGuideLink]="productGuideLink"
      pageInfoWidth="full"
      [pageDescriptiveText]="!!customDivText ? customDivText : ''"
    >
    </portal-page-info>
    <div class="flex-row flex-layout-align-space-between">
      <div class="flex-66">
        <hr>
        Create a new <strong>Organisation</strong>.<br>
        We will start by creating an (initial) account for you.<br>
        We will then ask you to name this Organisation.<br>
        We will then ask what domain-name you wish to access your applications from.<br>

      </div>
      <div class="flex-40">
      <iframe [src]='signupVideoURL' allowfullscreen title="Signup Walkthrough" allow="accelerometer; autoplay; gyroscope; picture-in-picture"></iframe>
      </div>
    </div>
    <mat-vertical-stepper [linear]="isLinear" [selectedIndex]="state.signupState.selected_index" #stepper>
      <mat-step [completed]="state.signupState.initial_auth_complete" [editable]="!state.signupState.initial_auth_complete">
        <ng-template matStepLabel class="header">Initial Account Setup</ng-template>
        <div *ngIf="state.signupState.selected_index === 0">
          <div class="flex-row">
            <div class="body flex-66">
              Create an initial account using your Apple, Google, or Microsoft identity.
              You are the initial administrator of this new organisation.<br>

              This is not granting Agilicus access to your account information, it is
              using them as an Identity provider for Authentication purposes.<br>

              See <a href="https://www.youtube.com/watch?v=srweN8Ko1cs" rel="noopener noreferrer" target="_blank">video</a> for more info.<br>

              Note: If you have previously used the Agilicus system,
              you may have setup multi-factor authentication, and receive a challenge here.<br>
            </div>
            <div class="flex-33">
              <img src="assets/img/sign-in.png" height=160 alt="allow popups top-right url bar">
            </div>
          </div>
          <button [disabled]="!authIsGood" mat-raised-button color="primary" class="sign-up-button" (click)="onLoginClick()"
            matTooltip="Click to choose a public sign-in"
            aria-label="A button that opens a new window to choose a sign-in">
            Sign-Up
          </button>
          <div *ngIf="!authIsGood" class="sign-up-error">
            <br>
            <p>
              <b>ERROR:</b> your corporate firewall is blocking <a href="{{this.issuer}}">{{this.issuer}}</a>,
              which prevents the signup flow.
            </p>

            <portal-product-guide-link
              productGuidePreText="See product guide for "
              productGuideLink="https://www.agilicus.com/anyx-guide/signup-firewall-configuration/"
              productGuideLinkText="more information"
              productGuidePostText="on how to diagnose and correct."
            >
            </portal-product-guide-link>

            <p>Correct the error and reload this page.</p>
            <br>
            </div>
        </div>
      </mat-step>
      <mat-step [completed]="state.signupState.create_complete" [editable]="!state.signupState.create_complete">
        <ng-template matStepLabel class="header">Create Organisation</ng-template>
        <div *ngIf="state.signupState.selected_index === 1">
          <form [formGroup]="orgForm" autocomplete="off">
            <div>
              <br>The name used for us to identify you on an invoice.<br>
              It will also appear in the administrative user interface.<br>
              <mat-form-field hintLabel="Max length of 100 characters.">
                <mat-label>Organisation/Company/Account Name</mat-label>
                <input matInput id="org-name" formControlName="orgName" required (keyup.enter)="keyTabManager.keyTab($event.target.id)">
                <mat-error>A valid organisation name is required</mat-error>
              </mat-form-field>
              <form [formGroup]="subdomainForm" autocomplete="off">
                <div class="body domain">
                  <portal-page-info
                    productGuidePreText="DNS Domain ("
                    productGuideLink="https://www.agilicus.com/anyx-guide/signup/#h-step-2-domain-selection"
                    productGuideLinkText="more"
                    productGuidePostText="information):"
                    pageInfoWidth="full"
                  >
                  </portal-page-info>

                  <mat-radio-group formControlName="option" aria-labelledby="subdomain option radio buttons"
                    class="column-radio-group">
                    <mat-radio-button [value]="subdomainOptionEnum.own">
                      I have set up my own cname alias
                    </mat-radio-button>
                    <mat-radio-button [value]="subdomainOptionEnum.agilicus_supplied"
                      (change)="autofillDomain(orgForm.value.orgName, $event.value)">
                      I have not set up my own cname alias
                    </mat-radio-button>
                  </mat-radio-group>
                </div>
              </form>
              <form [formGroup]="subdomainOwnForm" autocomplete="off">
                <div *ngIf="subdomainForm.value.option === subdomainOptionEnum.own">
                  <mat-form-field
                    [hintLabel]="subdomainHintLabel">
                    <mat-label>(sub)domain (e.g. cloud.example.com)</mat-label>
                    <input matInput id="domain-own-name" formControlName="domain" required (keyup.enter)="keyTabManager.keyTab($event.target.id)" #domainNameInput>
                    <mat-error *ngIf="!subdomainOwnForm.get('domain').errors?.cnameNotResolved">A valid domain is required
                    </mat-error>
                    <mat-error *ngIf="subdomainOwnForm.get('domain').errors?.cnameNotResolved">Unable to resolve CNAME. Did
                      you assign the appropriate CNAME? Is your DNS split-horizon? Was it setup as a wildcard?</mat-error>
                  </mat-form-field>
                  <button style="margin-left: 20px;" mat-raised-button color="primary" (click)='verifySubdomain()'>
                    Validate
                  </button>
                  <div class="validation-stepper">
                    <lib-stepper-progress-bar
                    [isVertical]="isVertical"
                    [controller]="progressStepper"
                    [steps]="steps">
                    </lib-stepper-progress-bar>
                  </div>
                  <div class="own-domain-instructions">
                    <br>
                    <portal-product-guide-link
                      productGuidePreText="NOTE: To use your own (sub)domain name,
                      you will need to assign a "
                      productGuideLink="https://www.agilicus.com/about-dns-cname/"
                      productGuideLinkText="CNAME"
                      [productGuidePostText]="productGuidePostText"
                    >
                    </portal-product-guide-link>

                    <p>
                      We recommend you create a CNAME for *.cloud.MYCOMPANY.com, this means that your users will always
                      access
                      https://&lt;appname&gt;.cloud.MYCOMPANY.com
                    </p>

                    <portal-product-guide-link
                      productGuidePreText="NOTE: If you run split-horizon DNS (e.g. 2 DNS servers, 1 internal, 1 external), make sure to "
                      productGuideLink="https://www.agilicus.com/anyx-guide/signup/#h-step-2-domain-selection"
                      productGuideLinkText="configure"
                      productGuidePostText="both."
                    >
                    </portal-product-guide-link>

                    <portal-product-guide-link
                      productGuidePreText="NOTE: Help on selecting a (sub) "
                      productGuideLink="https://www.agilicus.com/anyx-guide/theory-of-operation-cname-domain/"
                      productGuideLinkText="domain and DNS setup"
                    >
                    </portal-product-guide-link>
                  </div>
                </div>
              </form>
              <form [formGroup]="subdomainAgilicusForm" autocomplete="off">
                <div *ngIf="subdomainForm.value.option === subdomainOptionEnum.agilicus_supplied">
                  <mat-form-field
                    hintLabel="Max length of 63 characters. Only letters (a-z), numbers (0-9), and hyphens (-) are allowed.">
                    <mat-label>Domain (e.g. my-company)</mat-label>
                    <div class="domain-field">
                      <input matInput id="domain-agilicus-name" formControlName="domain" required (keyup.enter)="keyTabManager.keyTab($event.target.id)">
                    </div>
                    <mat-error>A valid subdomain is required (no capitals, no spaces, no underscores, must start with a
                      letter.)</mat-error>
                  </mat-form-field>

                  <mat-form-field>
                    <mat-label>Hosting domain</mat-label>
                    <mat-select
                      #selectHostingDomain
                      formControlName="hostingDomain"
                      (keyup.enter)="keyTabManager.keyTabSelect($event.target.id, selectHostingDomain)"
                    >
                      <mat-option
                        *ngFor="let entry of getHostingDomainsToDisplay()"
                        value="{{ entry }}"
                      >
                        .{{ entry }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </form>
            </div>
            <button
              mat-raised-button
              id="create-org-btn"
              color="primary"
              class="sign-up-button"
              matTooltip="Click to create the organisation"
              aria-label="A button to create the organisation"
              [disabled]="disableCreateOrgButton() || disableOrgIfCnameValidationFails()"
              (click)="verifyAndCreate()"
            >
              CREATE
            </button>
            <div *ngIf="showProgress" class="create-progress-bar">
              Creating your organisation...<br>
              <mat-progress-bar color="primary" mode="indeterminate">
              </mat-progress-bar>
              <small>{{ getProgressBarEstimateText() }}</small>
            </div>
          </form>
        </div>
      </mat-step>
      <mat-step [completed]="!state.signupState.is_signing_up" [editable]="state.signupState.is_signing_up">
        <ng-template matStepLabel class="header">Re-Authentication</ng-template>
        <div *ngIf="state.signupState.selected_index === 2" class="body">
          <p>Your organisation has been created at <b>{{ domain }}</b>.</p>
          <p>You will administer it from  <b>https://admin.{{domain}}</b>, and your end-users may request access to applications
            and control their multi-factor authentication preferences via <b>https://profile.{{domain}}</b></p>
          <p>
            For security purposes, we require that your account is re-authenticated with your provided credentials.
            Please click on the button below to re-authenticate and begin configuring your firewall settings,
            applications, and users.
          </p>
          <button mat-raised-button color="primary" class="sign-up-button"
            matTooltip="Click to log in to your new organisation"
            aria-label="A button to log in to your new organisation" (click)="signupBounce()">
            AUTHENTICATE
          </button>
        </div>
      </mat-step>
      <mat-step>
        <ng-template matStepLabel class="header">Get Started</ng-template>
        <div *ngIf="state.signupState.selected_index === 3">
          <div class="body">
              <h2>Welcome to Agilicus AnyX!</h2>
              Your custom admin URL is shown in the title bar ({{this.adminURL}}).<br>
              Please <strong>bookmark</strong> this page now.<br><br>

              Your end-user profile URL is ({{this.getProfileUrlForLinkFunc()}}).<br>
              Navigate to <a [href]="getProfileUrlForLinkFunc()" target="_blank">profile</a> to set
              up multi-factor authentication, and to access resources.<br><br>
              You will shortly receive a welcome email with these URL.<br><br>
          </div>
          <button
            type="button"
            mat-raised-button
            color="primary"
            class="get-started-now-button"
            matTooltip="Click to access our getting started guide"
            aria-label="Button to launch the getting started guide"
            (click)="getStartedNow()"
          >
            <mat-icon>touch_app</mat-icon> GET STARTED NOW
          </button>
        </div>
      </mat-step>
    </mat-vertical-stepper>
          If you have any questions, suggestions for improvement, feedback, complaints, please
          use the <strong>chat</strong> icon, or, email <a href="mailto:support@agilicus.com">support@agilicus.com</a>.<br>
          Any and all comments are welcome!<br>
  </div>

</ng-container>
