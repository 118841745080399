import * as sha256 from 'fast-sha256';
import { Inject, PLATFORM_ID, Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { ActivatedRoute, NavigationEnd, ParamMap, Router } from '@angular/router';

import { Store, select } from '@ngrx/store';
import { Subject, Observable, combineLatest, of } from 'rxjs';
import { catchError, map, takeUntil } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';

import { AuthService } from '@app/core/services/auth-service.service';

import { User, Organisation, UserMetadata, FilesService } from '@agilicus/angular';

import { AppState, selectSignupState } from '@app/core';
import { environment as env, environment } from '@env/environment';

import { NavMenuItem, SideNavMenuComponent } from './shared/components/submenu/submenu.component';
import { MergedRoute, getMergedRoute } from './core/merged-route';
import {
  selectUser,
  selectCurrentOrg,
  selectMemberOrgs,
  selectAdminPortalUserMetadata,
  selectGettingStartedData,
} from './core/user/user.selectors';
import { ActionUserLogout, ActionUserInit, ActionUserHidePaymentReminder } from './core/user/user.actions';
import { capitalizeFirstLetter, getUserNameFromUser, replaceCharacterWithSpace } from './shared/components/utils';
import { SignupState } from './core/signup/signup.models';
import { ActionSignupAuthComplete, ActionSignupCancelSignup } from './core/signup/signup.actions';
import { getAdminPortalDataFromUserMetadata } from './core/user/preferences/user-preference-utils';
import { AdminPortalData } from './core/user/preferences/admin-portal-data';
import { dateIsWithinDurationAgo, getDaysActive } from './shared/components/date-utils';
import { getDaysSinceSignup, signupInProgress } from './core/signup/signup-utils';
import { initBillingAccountFull } from './core/billing-state/billing-account-full.actions';
import {
  selectBillingAccountFullDefaultPaymentMethod,
  selectBillingAccountFullIsStarterPlan,
  selectBillingAccountFullRefreshDataValue,
  selectCurrentBillingAccountFull,
} from './core/billing-state/billing-account-full.selectors';
import { createDialogData } from './shared/components/dialog-utils';
import { ConfirmationDialogComponent } from './shared/components/confirmation-dialog/confirmation-dialog.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import {
  CurrentUsageAlertData,
  DatePeriodData,
  doNotNeedPaymentNag,
  getBillingAccountFullEstimatedBalanceEndDateData,
  getBillingAccountFullTrialPeriodData,
  getEmptyCurrentUsageAlertData,
  getEstimateBalanceEndDateMax,
  getPaymentDialogEndMessage,
  getSubscriptionButtonText,
  isStarterPlanWithoutPayment,
  shouldAlertOnCurrentUsageData,
  TrialPeriodData,
} from './core/billing-state/billing-api-utils';
import { getSubdomainFromIssuer } from './core/issuer-state/issuer.utils';
import { getIgnoreErrorsHeader } from './core/http-interceptors/http-interceptor-utils';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { IsLoggedInService } from './core/services/is-logged-in.service';
import { TourService } from 'ngx-ui-tour-md-menu';
import { getTourSteps, openSideMenuForTourNextStep } from './shared/components/tour.utils';
import { selectAlertsState, selectNavMenuItemsListState, selectUIStateVersion } from './core/ui/ui.selectors';
import {
  getAllParentAndNestedSideNavMenuItems,
  getAllParentAndNestedSideNavMenuItemsFromParentMenuItems,
} from './shared/components/submenu/side-nav-menu-definitions';
import {
  ActionUILoadSideNavMenuUIState,
  ActionUISetVersion,
  ActionUIUpdateAlertsBalanceEndDateState,
  ActionUIUpdateAlertsCurrentUsageState,
} from './core/ui/ui.actions';
import { AlertsState } from './core/models/ui/ui-model';
import { EventsService } from './core/services/events.service';
import { cloneDeep } from 'lodash-es';
import { GettingStartedData } from './core/user/preferences/getting-started-data';
import { BillingAccountFull } from './core/models/billing/billing-account-full';
import { PaymentDialogComponent, PaymentDialogData } from './shared/components/payment-dialog/payment-dialog.component';
import { PortalVersion } from './shared/components/portal-version.enum';
import { DynamicEnvironmentService } from './core/services/dynamic-environment.init';

declare var gtag;
declare var Tawk_API;
declare function mautic(action: string, type: string, params: object | undefined): void;

interface ComponentContext {
  user: User;
  orgs: Organisation[];
  currentOrg: Organisation;
  currentOrgId: string;
  isApiAuthenticated: boolean;
}
declare global {
  interface Window {
    dataLayer: any[];
  }
}

const autoLoginHoldoffSeconds: number = 120;

@Component({
  selector: 'portal-app',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  private unsubscribe$: Subject<void> = new Subject<void>();
  private isProd = env.production;
  private envName = env.envName;
  private signaledSignin = false;

  private mergedRoute: MergedRoute;
  public isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset).pipe(map((result) => result.matches));
  public signupState: SignupState;
  public adminPortalUserMetadata: UserMetadata;
  public adminPortalData: AdminPortalData;
  public context$: Observable<ComponentContext>;
  public currentRouterLink = '';
  public currentPortalVerion: PortalVersion = PortalVersion.stable;
  public sideNavMenuItemsList: Array<NavMenuItem>;
  private dialogOpen = false;
  private routedToBilling = false;
  private routedToGettingStarted = false;
  private trialPeriodData: TrialPeriodData;
  private estimatedBalanceEndDateData: DatePeriodData;
  private alertOnCurrentUsageData: CurrentUsageAlertData = getEmptyCurrentUsageAlertData();
  public brandLogo: string | SafeUrl | undefined | null;
  private isLoggedIn: boolean;
  private tourSteps = getTourSteps();
  private alertsState: AlertsState;
  private gettingStartedDataCopy: GettingStartedData;
  private queryParamMap: ParamMap;
  private refreshBillingParam: string;
  private refreshBillingDataStateValue = 0;
  public paymentMethod = undefined;
  public isStarterPlan = undefined;

  public capitalizeFirstLetter = capitalizeFirstLetter;

  // This is required in order to reference the enum in the html template.
  public portalVersion = PortalVersion;

  @ViewChild('sideNavMenuComp') sideNavMenuComp: SideNavMenuComponent;

  constructor(
    @Inject(PLATFORM_ID)
    private platformId: object,
    private breakpointObserver: BreakpointObserver,
    private store: Store<AppState>,
    public router: Router,
    public route: ActivatedRoute,
    private cookieService: CookieService,
    private authService: AuthService,
    public trialDialog: MatDialog,
    public estimatedBalanceEndDateDialog: MatDialog,
    public currentUsageDialog: MatDialog,
    public paymentDialog: MatDialog,
    private filesService: FilesService,
    private sanitizer: DomSanitizer,
    private isLoggedInService: IsLoggedInService,
    private tourService: TourService,
    private eventsService: EventsService,
    private envService: DynamicEnvironmentService
  ) {}

  private setCurrentPortalVersion(cookie: PortalVersion): void {
    this.currentPortalVerion = cookie;
  }

  private sendSigninInfoWWW(userResp: User): void {
    const emailCookie = this.cookieService.get('__HOST-ag_email') || this.cookieService.get('ag_email');

    if (!userResp || !userResp.email || this.signaledSignin) {
      return;
    }
    const emailCookieDecoded = decodeURI(emailCookie);
    if (emailCookieDecoded === userResp.email) {
      return;
    }
    // The cookie serves to reduce the 'signin' events to 1 per day
    // otherwise each reload of tab fires the code again
    if (window.location.protocol === 'https:') {
      this.cookieService.set('__HOST-ag_email', encodeURI(userResp.email), { expires: 1, secure: true });
    } else {
      this.cookieService.set('ag_email', encodeURI(userResp.email), { expires: 1, secure: false });
    }

    try {
      const email = userResp.email.toLowerCase();
      const te = new TextEncoder();
      const hash = sha256.hmac(te.encode(env.tawk_key), te.encode(email));
      const hashHex = Array.prototype.map.call(hash, (x) => x.toString(16).padStart(2, '0')).join('');
      const url =
        'https://www.agilicus.com/setistate?' +
        new URLSearchParams({
          em: email,
          org: userResp.org_id,
          tawk_hash: hashHex,
        });
      const config = {
        method: 'GET',
        mode: 'no-cors' as RequestMode,
        credentials: 'include' as RequestCredentials,
        headers: {
          'ngsw-bypass': '',
        },
      };
      fetch(url, config).then((response) => console.log(response));
    } catch (err) {
      console.log('error sending w-state: ', err);
    }

    try {
      mautic('send', 'pageview', {
        page_title: 'signin',
        page_url: window.location.href,
        utm_source: 'portal',
        utm_campaign: 'signin',
        email: userResp.email.toLowerCase(),
        firstname: userResp.first_name,
        lastname: userResp.last_name,
        tags: 'signin',
      });
    } catch (err) {
      console.log('error sending mautic state', err);
    }
    this.signaledSignin = true;
  }

  private subscribeToTourServiceEvents(): void {
    this.tourService.events$.subscribe((event) => {
      // This will open the correct side menu when moving to the next tour step
      openSideMenuForTourNextStep(event, this.tourSteps, this.store);
    });
  }

  private doAutomaticSignIn(): boolean {
    if (!this.authService.isRememberMeCookieSet()) {
      return false;
    }

    if (this.isNavigatingToSignup()) {
      // We do not want autologin if we're on the signup page.
      return false;
    }

    const lastLogin = this.authService.lastLoginAttempt();
    if (!lastLogin) {
      return true;
    }

    return !dateIsWithinDurationAgo(lastLogin, autoLoginHoldoffSeconds);
  }

  /*
   * In dev mode, passes through the observable while emitting its stream of values.
   * In prod mode, just passes through the observable with no side effects.
   */
  private logChangedPipe<T>(name: string): (source: Observable<T>) => Observable<T> {
    if (this.isProd) {
      return (source: Observable<T>) => source;
    }

    return (source: Observable<T>) => {
      return new Observable((subscriber) => {
        return source.subscribe({
          next(value: T) {
            console.log(`logChangedPipe: ${name} changed`, JSON.stringify(value));
            subscriber.next(value);
          },
        });
      });
    };
  }

  private handleRouterParams(): void {
    this.route.queryParamMap.subscribe((params) => {
      this.queryParamMap = params;
      this.refreshBillingParam = params.get('refresh_billing');
      const logout = params.get('logout');
      if (!!logout) {
        localStorage.clear();
        this.router.navigate(['/']);
        return;
      }
      const bid = params.get('bid');
      const plo = params.get('plo');
      const flush = params.get('flush');
      const mtc_id = params.get('mtc_id');
      const mautic_device_id = params.get('mautic_device_id');
      if (mtc_id) {
        this.cookieService.set('mtc_id', mtc_id);
        localStorage.setItem('mtc_id', mtc_id);
      }
      if (mautic_device_id) {
        this.cookieService.set('mautic_device_id', mautic_device_id);
        localStorage.setItem('mautic_device_id', mautic_device_id);
      }

      if (flush === '1' && !this.isOnSignupFromLocation()) {
        this.nukeState();
        this.router.navigate(['/'], { queryParams: { flush: null, bid: bid, plo: plo, mtc_id: null, mautic_device_id: null } });
        //        location.href = '/';
      }
    });
  }

  public async ngOnInit(): Promise<void> {
    this.subscribeToTourServiceEvents();

    this.store.dispatch(initBillingAccountFull({ force: true, blankSlate: false }));
    const cookie = this.cookieService.get('ver');
    this.setCurrentPortalVersion(this.envService.getPortalVersion());
    this.handleRouterParams();
    const storedVersionData$ = this.store.pipe(select(selectUIStateVersion));
    const sideNavData$ = this.store.pipe(select(selectNavMenuItemsListState));
    const refreshBillingDataState$ = this.store.pipe(select(selectBillingAccountFullRefreshDataValue));
    combineLatest([storedVersionData$, sideNavData$, refreshBillingDataState$])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([storedVersionDataResp, sideNavMenuListResp, refreshBillingDataStateResp]) => {
        this.refreshBillingDataStateValue = refreshBillingDataStateResp;
        if (storedVersionDataResp !== this.version) {
          // If the version of portal has been updated we want to
          // update the version stored in ngrx which in turn will
          // reload the side nav menu state via an effect.
          this.store.dispatch(new ActionUISetVersion(this.version));
          return;
        }
        if (!!sideNavMenuListResp) {
          const allExpectedParentAndNestedSideNavMenuItems = getAllParentAndNestedSideNavMenuItems();
          const allSetParentAndNestedSideNavMenuItems = getAllParentAndNestedSideNavMenuItemsFromParentMenuItems(sideNavMenuListResp);
          if (allExpectedParentAndNestedSideNavMenuItems.length !== allSetParentAndNestedSideNavMenuItems.length) {
            // The side menu items have changed from those stored in state, so we need to update them:
            this.store.dispatch(new ActionUILoadSideNavMenuUIState());
            return;
          }
          this.sideNavMenuItemsList = sideNavMenuListResp;
          if (!!this.sideNavMenuComp) {
            this.sideNavMenuComp.triggerChangeDetectionFromParentComponent();
          }
        }
      });

    const mergedRoute$ = this.store.pipe(select(getMergedRoute), this.logChangedPipe('mergedRoute'));
    const orgs$ = this.store.pipe(select(selectMemberOrgs), this.logChangedPipe('memberOrgs'));
    const user$ = this.store.pipe(select(selectUser), this.logChangedPipe('User'));
    const currentOrg$ = this.store.pipe(select(selectCurrentOrg), this.logChangedPipe('currentOrg'));
    const signupState$ = this.store.pipe(select(selectSignupState), this.logChangedPipe('signupState'));
    const adminPortalUserMetadata$ = this.store.pipe(select(selectAdminPortalUserMetadata), this.logChangedPipe('userMetadata'));
    const currentBillingAccount$ = this.store.pipe(select(selectCurrentBillingAccountFull), this.logChangedPipe('currentBillingAccount'));
    const defaultPaymentMethod$ = this.store.pipe(select(selectBillingAccountFullDefaultPaymentMethod));
    const isStarterPlan$ = this.store.pipe(select(selectBillingAccountFullIsStarterPlan));
    const gettingStartedData$ = this.store.pipe(select(selectGettingStartedData));
    const isLoggedIn$ = this.isLoggedInService.getIsLoggedIn().pipe(this.logChangedPipe('isLoggedIn'));
    let customLogo$: Observable<Blob | undefined | null> = of(undefined);
    if (this.brandLogo === undefined) {
      customLogo$ = this.getCustomLogo$().pipe(this.logChangedPipe('customLogo'));
    }
    const alertsState$ = this.store.pipe(select(selectAlertsState));

    this.context$ = combineLatest([
      user$,
      currentOrg$,
      orgs$,
      signupState$,
      adminPortalUserMetadata$,
      mergedRoute$,
      currentBillingAccount$,
      defaultPaymentMethod$,
      isStarterPlan$,
      gettingStartedData$,
      customLogo$,
      isLoggedIn$,
      alertsState$,
    ]).pipe(
      map(
        ([
          userResp,
          currentOrgResp,
          orgsResp,
          signupStateResp,
          adminPortalUserMetadataResp,
          mergedRouteResp,
          currentBillingAccountResp,
          defaultPaymentMethodResp,
          isStarterPlanResp,
          gettingStartedDataResp,
          customLogoResp,
          isLoggedInResp,
          alertsStateResp,
        ]) => {
          this.isLoggedIn = isLoggedInResp !== undefined ? isLoggedInResp : false;
          this.mergedRoute = mergedRouteResp;
          this.signupState = signupStateResp;
          this.adminPortalUserMetadata = adminPortalUserMetadataResp;
          this.adminPortalData = getAdminPortalDataFromUserMetadata(this.adminPortalUserMetadata);
          this.paymentMethod = defaultPaymentMethodResp;
          this.isStarterPlan = isStarterPlanResp;
          this.trialPeriodData = getBillingAccountFullTrialPeriodData(currentBillingAccountResp, currentOrgResp);
          this.estimatedBalanceEndDateData = getBillingAccountFullEstimatedBalanceEndDateData(currentBillingAccountResp, currentOrgResp);
          this.alertsState = alertsStateResp;
          this.gettingStartedDataCopy = cloneDeep(gettingStartedDataResp);
          this.alertOnCurrentUsageData = shouldAlertOnCurrentUsageData(
            currentBillingAccountResp,
            currentOrgResp,
            this.alertsState?.currentUsage
          );
          if (customLogoResp !== undefined && this.brandLogo === undefined) {
            this.brandLogo = !!customLogoResp ? this.sanitizer.bypassSecurityTrustUrl(window.URL.createObjectURL(customLogoResp)) : null;
          }

          this.sendSigninInfoWWW(userResp);

          const result: ComponentContext = {
            user: userResp,
            currentOrg: currentOrgResp,
            currentOrgId: currentOrgResp ? currentOrgResp.id : undefined,
            orgs: this.sortByName([...orgsResp]),
            isApiAuthenticated: this.isLoggedIn,
          };
          this.authService.numberOrgs = result.orgs.length;
          if (!this.mergedRoute) {
            // The merged route is a basic building block that has no other dependencies. We make a bunch
            // of decisions based off it, so make sure it's set before we do anything.
            return result;
          }

          if (!!userResp) {
            localStorage.setItem('email', userResp.email);
          }

          if ((window as any).__karma__ === undefined && Tawk_API !== undefined && userResp !== undefined) {
            const te = new TextEncoder();
            const hash = sha256.hmac(te.encode(env.tawk_key), te.encode(userResp.email));
            const hashHex = Array.prototype.map.call(hash, (x) => x.toString(16).padStart(2, '0')).join('');

            let orgName = 'unknown';
            let issuerName = 'unknown';

            if (currentOrgResp !== undefined) {
              orgName = currentOrgResp.organisation;
              issuerName = currentOrgResp.issuer;
            }
            const tawk_attr = {
              email: userResp.email,
              id: userResp.id,
              org: userResp.org_id,
              'org-name': orgName,
              issuer: issuerName,
              page: this.router.url,
              hash: hashHex,
            };
            const userName = getUserNameFromUser(userResp);
            if (!!userName) {
              tawk_attr['name'] = userName;
            }
            window.localStorage.setItem('tawk_attr', JSON.stringify(tawk_attr));
            Tawk_API.visitor = tawk_attr;
          }

          if (!!userResp && signupStateResp.is_signing_up && !signupStateResp.initial_auth_complete) {
            if (signupStateResp.is_redirecting) {
              this.store.dispatch(new ActionSignupAuthComplete(userResp.org_id));
              // signup in progress. Redirect to the signup screen.
              this.routeToSignup();
              return result;
            }
          }
          if (this.isSignupReAuthComplete(userResp) && !this.isNavigatingToSignup()) {
            if (!signupStateResp.is_redirecting) {
              return result;
            }
            if (!this.isSignupComplete(userResp)) {
              // reAuth is complete
              return result;
            }
          }
          if (!!userResp && !signupStateResp.is_signing_up && !this.isNavigatingToSignup()) {
            this.doNotificationDialogAndRoutingChecks(currentOrgResp, userResp);
          }
          this.cancelSignupStateIfStale();
          this.cancelSignupStateIfSignupCancelledBeforeLogin(signupStateResp);
          if (this.doAutomaticSignIn() && !this.isLoggedIn) {
            this.handleLoginClick();
          }
          return result;
        }
      )
    );

    if (env.envName === 'production') {
      this.router.events.forEach((item) => {
        if (item instanceof NavigationEnd) {
          let uid;
          let org_id;
          if (this.authService && this.authService.localAuth && this.authService.localAuth) {
            uid = this.authService.localAuth.id();
            org_id = this.authService.localAuth.org();
          }
        }
      });
    }

    this.store.dispatch(new ActionUserInit());
  }

  private doNotificationDialogAndRoutingChecks(currentOrgResp: Organisation, currentUserResp: User): void {
    if (!!this.envService.environment?.overrideAlertDialogs) {
      // This will prevent always seeing the trial dialog on a test org in dev mode
      return;
    }
    const trialDaysRemaining = this.trialPeriodData?.daysRemaining;
    if (!!trialDaysRemaining && trialDaysRemaining <= 5 && !this.routedToBilling && !this.dialogOpen) {
      this.routeToBilling();
      this.openTrialNotificationDialog(this.trialPeriodData.daysRemaining);
      return;
    }
    if (
      !this.alertsState?.balanceEndDate === false &&
      this.estimatedBalanceEndDateData?.daysRemaining !== undefined &&
      this.estimatedBalanceEndDateData.daysRemaining <= getEstimateBalanceEndDateMax() &&
      !this.routedToBilling &&
      !this.dialogOpen
    ) {
      this.routeToBilling();
      this.openEstimatedBalanceEndDateNotificationDialog(this.estimatedBalanceEndDateData?.daysRemaining);
      return;
    }
    if (!!trialDaysRemaining && trialDaysRemaining < 20 && !this.dialogOpen) {
      this.openTrialNotificationDialog(this.trialPeriodData.daysRemaining);
    }
    if (!!this.alertOnCurrentUsageData?.shouldAlert && !this.routedToBilling && !this.dialogOpen) {
      this.routeToBilling();
      this.openCurrentUsageNotificationDialog();
    }
    if (this.shouldOpenGettingStartedPaymentReminderDialog(currentOrgResp, currentUserResp)) {
      this.openGettingStartedPaymentReminderDialog(currentOrgResp);
    } else if (this.mergedRoute?.url == '/' && this.showGettingStartedOnLogin() && !this.routedToGettingStarted) {
      this.routeToGettingStarted();
    }
  }

  private shouldOpenGettingStartedPaymentReminderDialog(currentOrgResp: Organisation, currentUserResp: User): boolean {
    if (!currentOrgResp) {
      // Do not have required data for checks.
      return false;
    }
    if (doNotNeedPaymentNag(this.paymentMethod, this.isStarterPlan, currentOrgResp, this.refreshBillingDataStateValue)) {
      return false;
    }
    if (!this.queryParamMap || (this.isStarterPlan === undefined && this.refreshBillingDataStateValue === 0)) {
      // Do not have required data for check.
      return false;
    }
    if (!!this.refreshBillingParam && this.refreshBillingDataStateValue === 0) {
      // Need to get new data from the api before doing the check.
      return false;
    }
    const daysActive = getDaysActive(currentOrgResp, currentUserResp);
    const hidePaymentReminder = this.gettingStartedDataCopy?.hide_payment_reminder;
    if (
      isStarterPlanWithoutPayment(this.isStarterPlan, this.paymentMethod) &&
      daysActive > 15 &&
      !this.dialogOpen &&
      hidePaymentReminder === false
    ) {
      // Open payment dialog
      return true;
    }
    return false;
  }

  private cancelSignupStateIfStale(): void {
    if (this.hasLeftSignupBeforeCreating()) {
      this.store.dispatch(new ActionSignupCancelSignup());
    }
  }

  /**
   * We cannot distinguish between a redirect from the primary app signing in, and the signup signing in.
   * To prevent stale state from an aborted signup messing up the primary app, detect whether such stale state is kicking around and squash it.
   * In particular, if we're not on the signup page, and we're not in the middle of redirecting, then if there's stale state, get rid of it.
   */
  private hasLeftSignupBeforeCreating(): boolean {
    return !this.isOnSignupFromLocation() && signupInProgress(this.signupState) && !this.signupState.is_redirecting;
  }

  private routeToSignup(): void {
    this.router.navigate(['/signup']);
  }

  private routeToGettingStarted(): void {
    this.routedToGettingStarted = true;
    this.router.navigate(['/getting-started']);
  }

  private routeToBilling(): void {
    // We do not want to route to billing every time we navigate to the home screen.
    this.routedToBilling = true;
    this.router.navigate(['/billing']);
  }

  public isNavigatingToSignup(): boolean {
    // 'isSigningUp' is added to the route when navigating to '/signup'.
    // See 'app-routing.module.ts'
    return this.mergedRoute?.data?.isSigningUp;
  }

  private isSignupReAuthComplete(user: User): boolean {
    return !!user && this.signupState?.final_auth;
  }

  private isSignupComplete(user: User): boolean {
    return !!user && this.signupState?.signup_complete;
  }

  public showSignupAndMenuWhenComplete(): boolean {
    return this.isNavigatingToSignup() && this.signupState?.create_complete;
  }

  public showSideMenu(componentContext: ComponentContext): boolean {
    return (
      !!componentContext?.isApiAuthenticated &&
      (this.showSignupAndMenuWhenComplete() || !this.isNavigatingToSignup()) &&
      !this.hideMenuOnSignup() &&
      !!this.sideNavMenuItemsList
    );
  }

  private sortByName(orgs: Organisation[]): Organisation[] {
    return orgs.sort((lhs: Organisation, rhs: Organisation) => {
      return lhs.organisation.localeCompare(rhs.organisation);
    });
  }

  public ngOnDestroy(): void {
    // Called once, before the instance is destroyed.
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  public handleLoginClick(): void {
    this.authService.triggerLogin();
  }

  public async handleLogoutClick(): Promise<void> {
    this.authService.triggerLogout();
  }

  private changeVersion(ver: string): void {
    this.setVerCookie(ver);
    this.store.dispatch(new ActionUserLogout('/', true));
  }

  public onVerClickAlpha(): void {
    this.currentPortalVerion = PortalVersion.alpha;
    this.changeVersion('alpha');
  }

  public onVerClickBeta(): void {
    this.currentPortalVerion = PortalVersion.beta;
    this.changeVersion('master');
  }

  public onVerClickStable(): void {
    this.currentPortalVerion = PortalVersion.stable;
    this.changeVersion('stable');
  }

  private setVerCookie(ver: string): void {
    this.cookieService.set('ver', ver, undefined, undefined, undefined, undefined, 'Lax');
  }

  public updateOrg(orgId: string): void {
    window.location.href = `${window.location.origin}/?org_id=${orgId}`;
  }

  /**
   * Receives the currentRouterLink from the active submenu component
   * so that other submenu components can be updated with this value.
   * Ensures only the current link is highlighted, or its parent if
   * the menu is collapsed.
   */
  public updateEvent(currentRouterLink: string): void {
    this.currentRouterLink = currentRouterLink.slice(currentRouterLink.indexOf('/') + 1);
  }

  /**
   * Checks if the menu link is currently selected.
   */
  public isLinkActive(routerLink: string): boolean {
    if (this.mergedRoute.url.startsWith('/' + routerLink)) {
      return true;
    }
    return false;
  }

  public hideMenuOnSignup(): boolean {
    return !!this.signupState?.is_signing_up;
  }

  /**
   * Will only show the getting started menu on login for 5 days after the user is created/signs up
   * or if the user manually dismisses the getting started.
   */
  public showGettingStartedOnLogin(): boolean {
    if (!this.adminPortalData?.gettingStarted) {
      return false;
    }
    if (this.adminPortalData.gettingStarted.hide_getting_started) {
      return false;
    }
    const daysSinceSignup = getDaysSinceSignup(this.adminPortalData);
    if (!!daysSinceSignup && daysSinceSignup >= 5) {
      return false;
    }
    return true;
  }

  private nukeState(): void {
    const keys = Object.keys(window.localStorage);
    for (const k of keys) {
      if (k !== 'mtc_id' && k !== 'mautic_device_id') {
        delete window.localStorage[k];
      }
    }
  }

  public openTrialNotificationDialog(trialDaysRemaining: number): void {
    this.dialogOpen = true;
    const messagePrefix = `Trial Subscription`;
    let message = '';
    if (trialDaysRemaining <= 5) {
      message += `Your Agilicus AnyX trial period has expired or will soon expire\n.<p><br>Please put a payment method on file to ensure continued service.`;
    } else {
      message += `Agilicus AnyX is in trial mode. Consider putting a payment method on file to ensure continued service.`;
    }
    message += getPaymentDialogEndMessage();
    const dialogData = createDialogData(messagePrefix, message);
    dialogData.icon = 'notification_important';
    const dialogRef = this.trialDialog.open(ConfirmationDialogComponent, {
      data: { ...dialogData, buttonText: { confirm: 'Continue', cancel: '' } },
    });
  }

  public openEstimatedBalanceEndDateNotificationDialog(daysRemaining: number): void {
    this.dialogOpen = true;
    const messagePrefix = `Subscription Balance`;
    let message = `Warning: Your remaining subscription balance will elapse in ${daysRemaining === 1 ? '1 day' : daysRemaining + ' days'}`;
    const dialogData = createDialogData(messagePrefix, message);
    const dialogRef = this.estimatedBalanceEndDateDialog.open(ConfirmationDialogComponent, {
      data: { ...dialogData, buttonText: { confirm: 'Continue', cancel: '' } },
    });
    dialogRef.afterClosed().subscribe(() => {
      this.alertsState.balanceEndDate = true;
      this.store.dispatch(new ActionUIUpdateAlertsBalanceEndDateState(true));
    });
  }

  public openCurrentUsageNotificationDialog(): void {
    this.dialogOpen = true;
    const messagePrefix = `Current Usage`;
    let message = `Info: Your current usage is above your minimum commitment of ${
      this.alertOnCurrentUsageData.minUsage
    } for "${replaceCharacterWithSpace(this.alertOnCurrentUsageData.metric, '_')}".`;
    const dialogData = createDialogData(messagePrefix, message);
    dialogData.icon = 'notification_important';
    const dialogRef = this.currentUsageDialog.open(ConfirmationDialogComponent, {
      data: { ...dialogData, buttonText: { confirm: 'Continue', cancel: '' } },
    });
    dialogRef.afterClosed().subscribe(() => {
      this.alertsState.currentUsage = true;
      this.store.dispatch(new ActionUIUpdateAlertsCurrentUsageState(true));
    });
  }

  public openGettingStartedPaymentReminderDialog(currentOrg: Organisation): void {
    this.eventsService.SendEvent(
      'payment_nag_getting_started',
      localStorage.email,
      `openGettingStartedPaymentReminderDialog: ${currentOrg.id}`,
      window.location.href
    );
    this.dialogOpen = true;
    const messagePrefix = `Reminder`;
    let message = `We notice you have been enjoying our product for a few days. 
    If you would like to expand your access, please click the "${getSubscriptionButtonText()}" button to update your subscription and add a payment method.`;
    message += getPaymentDialogEndMessage();
    const dialogData: PaymentDialogData = {
      messagePrefix,
      message,
      showDoNotShowButton: true,
      orgId: currentOrg.id,
    };
    const dialogRef = this.paymentDialog.open(PaymentDialogComponent, {
      data: dialogData,
    });
    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result === false) {
        this.store.dispatch(new ActionUserHidePaymentReminder());
        return;
      }
    });
  }

  private getCustomLogo$(): Observable<Blob | null> {
    return this.filesService
      .getDownloadPublic(
        {
          tag: 'agilicus-theme',
          subdomain: getSubdomainFromIssuer(window.location.href),
          file_in_zip: 'theme/my-login.svg',
        },
        'body',
        getIgnoreErrorsHeader()
      )
      .pipe(
        catchError((_) =>
          // If no svg file then look for a png file:
          this.filesService
            .getDownloadPublic(
              {
                tag: 'agilicus-theme',
                subdomain: getSubdomainFromIssuer(window.location.href),
                file_in_zip: 'theme/my-login.png',
              },
              'body',
              getIgnoreErrorsHeader()
            )
            // If no logo exists then return null:
            .pipe(catchError((_) => of(null)))
        )
      );
  }

  private onSignupPage(): boolean {
    return window.location.href.indexOf('signup') >= 0;
  }

  public getBrandLogo(): string | SafeUrl {
    return !!this.brandLogo && (!!this.onSignupPage() || !!this.signupState.is_signing_up)
      ? this.brandLogo
      : 'assets/img/Agilicus-logo-horizontal-white.svg';
  }

  private isOnSignupFromLocation(): boolean {
    return window.location.pathname.startsWith('/signup');
  }

  private signupCancelledBeforeLogin(signupState: SignupState): boolean {
    return (
      !this.isOnSignupFromLocation() &&
      signupState.is_signing_up === true &&
      signupState.initial_auth_complete === false &&
      signupState.create_complete === false &&
      signupState.signup_bounce === false &&
      signupState.final_auth === false &&
      signupState.signup_complete === false &&
      signupState.selected_index === 0 &&
      signupState.is_redirecting == false
    );
  }

  /**
   * This situation can occur if a user, that is not logged in,
   * navigates to the "/signup" route and then back to the root url
   * without logging in. In this case, we need to cancel/reset the
   * signup state so that the user sees the correct information on
   * the screen.
   */
  private cancelSignupStateIfSignupCancelledBeforeLogin(signupState: SignupState): void {
    if (this.signupCancelledBeforeLogin(signupState)) {
      this.store.dispatch(new ActionSignupCancelSignup());
    }
  }

  get version(): string {
    // Purposely not using the override, use the compiled in version
    return env.versions.app;
  }
}
