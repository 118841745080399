import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
  ChangeDetectorRef,
  OnDestroy,
  ViewChildren,
  QueryList,
} from '@angular/core';
import { Store, select } from '@ngrx/store';
import { AppState } from '@app/core';
import { getMergedRoute, MergedRoute } from '@app/core/merged-route';
import { combineLatest, Observable, Subject } from 'rxjs';
import { Router, NavigationEnd } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { selectApiOrgId } from '@app/core/user/user.selectors';
import { ActionUIUpdateSideNavMenuItem } from '@app/core/ui/ui.actions';
import { ProfileLinkService } from '@app/core/api/profile-link/profile-link.service';
import { AllPermissions, getCombinedPermissionsData$ } from '@app/core/user/permissions/utils';
import { PermissionsDefinition } from '@app/core/user/permissions/permissions-definition.enum';

/**
 * If NavMenuItem has both a routerLink and items the routerLink will be ignored
 */
export interface NavMenuItem {
  id: string;
  name: string;
  requiredPermissions: Array<PermissionsDefinition> | undefined;
  disabled: boolean;
  routerLink?: string;
  items?: Array<NavMenuItem>;
  showMenu?: boolean;
  icon?: string;
}

@Component({
  selector: 'portal-side-nav-menu',
  templateUrl: './submenu.component.html',
  styleUrls: ['./submenu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SideNavMenuComponent implements OnInit, OnDestroy {
  private unsubscribe$: Subject<void> = new Subject<void>();
  @Input() public nestLevel = 0;
  @Input() public navMenuItems: Array<NavMenuItem> = [];
  @Input() public currentRouterLink: string;
  @Output() public updateEvent = new EventEmitter<string>();

  private mergedRoute$: Observable<MergedRoute>;
  private mergedRoute: MergedRoute;
  private org_id$: Observable<string>;
  public org_id: string;
  private allPermissions: AllPermissions;

  @ViewChildren('nestedSideNavMenu') private nestedSideNavMenus: QueryList<SideNavMenuComponent>;

  constructor(
    private store: Store<AppState>,
    private router: Router,
    private changeDetector: ChangeDetectorRef,
    private profileLinkService: ProfileLinkService
  ) {}

  public ngOnInit(): void {
    this.mergedRoute$ = this.store.pipe(select(getMergedRoute));
    const permissions$ = getCombinedPermissionsData$(this.store);
    combineLatest([this.mergedRoute$, permissions$])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([mergedRouteResp, permissionsResp]) => {
        this.allPermissions = permissionsResp;
        this.mergedRoute = mergedRouteResp;
        this.changeDetector.detectChanges();
      });

    this.router.events.pipe(takeUntil(this.unsubscribe$)).subscribe((ev) => {
      if (ev instanceof NavigationEnd) {
        // We need to trigger this check manually
        // when using back and forth navigation.
        this.isChildRouteSelected(this.navMenuItems);
        this.changeDetector.detectChanges();
      }
    });

    this.org_id$ = this.store.pipe(select(selectApiOrgId));
    this.org_id$.pipe(takeUntil(this.unsubscribe$)).subscribe((resp) => {
      this.org_id = resp;
      this.changeDetector.detectChanges();
    });
  }

  public ngOnDestroy(): void {
    this.changeDetector.detach();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  public menuHasAllPermissions(navMenuItem: NavMenuItem): boolean {
    if (!navMenuItem.requiredPermissions) {
      return navMenuItem.disabled;
    }
    for (const permission of navMenuItem.requiredPermissions) {
      if (!this.allPermissions[permission]) {
        return false;
      }
    }
    return true;
  }

  public toggleMenu(menuItem: NavMenuItem): void {
    menuItem.showMenu = !menuItem.showMenu;
    this.store.dispatch(new ActionUIUpdateSideNavMenuItem(menuItem));
  }

  /**
   * Checks if the child link of the menu is currently the selected router link.
   */
  public isChildRouteSelected(menuItems: Array<NavMenuItem>): boolean {
    if (this.mergedRoute === undefined || !this.mergedRoute) {
      return false;
    }
    for (const item of menuItems) {
      if (!!item.items) {
        const isSelected = this.isChildRouteSelected(item.items);
        if (isSelected) {
          return true;
        }
      }
      if (this.mergedRoute.url.startsWith('/' + item.routerLink)) {
        return true;
      }
    }
    return false;
  }

  public updateCurrentLink(currentRouterLink: string): void {
    this.updateEvent.emit(currentRouterLink);
  }

  /**
   * Checks if the menu link is currently selected.
   */
  public isLinkActive(routerLink: string): boolean {
    if (this.mergedRoute !== undefined && this.mergedRoute) {
      if (this.mergedRoute.url.startsWith('/' + routerLink)) {
        return true;
      }
    }
    return false;
  }

  public getLeftPadValue(disabled?: boolean): string {
    let paddingValue = 15 * this.nestLevel;
    if (this.nestLevel > 1) {
      paddingValue += 10;
    }
    if (!!disabled) {
      paddingValue += 15;
    }
    return `${paddingValue}px`;
  }

  public incrementNestLevel(): number {
    return this.nestLevel + 1;
  }

  public navToProfile(): void {
    this.profileLinkService
      .getProfileUrlForLink$()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((link) => {
        window.open(link, '_blank');
      });
  }

  public triggerChangeDetectionFromParentComponent(): void {
    const nestedSideNavMenusArray = !!this.nestedSideNavMenus ? this.nestedSideNavMenus.toArray() : [];
    for (const nestedSideNavMenu of nestedSideNavMenusArray) {
      nestedSideNavMenu.triggerChangeDetectionFromParentComponent();
    }
    this.changeDetector.detectChanges();
  }
}
