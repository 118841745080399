<portal-page-info
  pageTitle="New Application"
  [pageDescriptiveText]="pageDescriptiveText"
  [productGuideLink]="productGuideLink"
>
</portal-page-info>

<div *ngIf="showNoPermissionsText()">
  <portal-no-permissions></portal-no-permissions>
</div>

<div
  *ngIf="hasAllPermissions() && !!currentApplicationModel"
  class="stepper-container"
>
  <mat-vertical-stepper
    linear
    #stepper
    portalMatVerticalStepperScroller
    (selectionChange)="onStepperSelectionChange($event.selectedIndex)"
  >
    <mat-step [stepControl]="appDetailsFormGroup">
      <form
        [formGroup]="appDetailsFormGroup"
        class="stepper-form"
        spellcheck="false"
        autocomplete="off"
        (keydown.enter)="$event.preventDefault()"
      >
        <ng-template matStepLabel>New application</ng-template>
        <portal-stepper-form-field
          placeholder="Enter the application name"
          [formCtrl]="appDetailsFormGroup.get('name')"
          required="true"
          tooltip="This will become the hostname in the URL that the users see"
          (formBlur)="onAppNameChange()"
        >
        </portal-stepper-form-field>

        <portal-stepper-form-field
          placeholder="Describe the application"
          [formCtrl]="appDetailsFormGroup.get('description')"
          required="true"
          tooltip="The end user will see this when asking for permission to use the application"
          (formBlur)="onFormBlur(appDetailsFormGroup, 'description', currentApplicationModel)"
        >
        </portal-stepper-form-field>

        <div class="stepper-nav-buttons">
          <button mat-button matStepperPrevious>Back</button>
          <button mat-button matStepperNext (click)="updateApplicationModel()">Next</button>
        </div>
      </form>
    </mat-step>

    <!-- FORK -->
    <div class="host-application-section">
      <mat-step [completed]="isAccessStepValid()">
        <ng-template matStepLabel>Access</ng-template>
        <label
          id="host-radio-group-label"
          class="radio-button-group-label"
        >
          My application is accessed:
        </label>
        <div [formGroup]="accessFormGroup">
          <mat-radio-group
            formControlName="selectedAccess"
            aria-labelledby="host selection radio buttons"
            class="host-radio-group"
          >
            <mat-radio-button
              *ngFor="let host of getFilteredAccessOptionData()"
              class="host-radio-button full-line-radio-button"
              [value]="host.value"
              (change)="onAccessOptionChange(host.value)"
              [disabled]="disableAccessOption(host.value)"
            >
              {{host.displayValue}}
            </mat-radio-button>
          </mat-radio-group>
        </div>

        <div *ngIf="showAccessOptions()">
          <portal-application-host-method-stepper
            [currentApplicationModel]="currentApplicationModel"
            [upstreamServiceFormGroup]="upstreamServiceFormGroup"
            [runtimeFormGroup]="runtimeFormGroup"
            [rewriteMediaTypesFormGroup]="rewriteMediaTypesFormGroup"
            [corsTypeFormGroup]="corsTypeFormGroup"
            [http2FormGroup]="http2FormGroup"
            [webApplicationFirewallFormGroup]="webApplicationFirewallFormGroup"
            [commonPathPrefixFormGroup]="commonPathPrefixFormGroup"
            [accessOptionChanged]="accessOptionChanged"
            [connectors]="connectors"
            [connectorFormGroup]="connectorFormGroup"
            [fqdnAliasOptionFormGroup]="fqdnAliasOptionFormGroup"
            [currentOrg]="currentOrg"
            (resetHostMethodOptionChanged)="resetHostMethodOptionChanged()"
          >
          </portal-application-host-method-stepper>
        </div>
       
        <div class="stepper-nav-buttons">
          <button mat-button matStepperPrevious>Back</button>
          <button mat-button matStepperNext (click)="updateApplicationModel()">Next</button>
        </div>
      </mat-step>
    </div>

    <!-- FORK -->
    <div class="auth-flow-section">
      <mat-step [stepControl]="authFlowFormGroup">
        <ng-template matStepLabel>Authentication</ng-template>
        <label
          id="auth-flow-radio-group-label"
          class="radio-button-group-label"
        >
          My application:
        </label>
        <mat-radio-group
          aria-labelledby="authentication flow selection radio buttons"
          class="auth-flow-radio-group"
          [(ngModel)]="appStepperState.selectedAuthFlow"
        >
          <span *ngFor="let authFlow of authFlowOptionData">
            <div
              [ngClass]="{ 'auth-proxy-radio-button-container': authFlow.value === authFlowOption.proxy }"
            >
              <mat-radio-button
                class="auth-flow-radio-button"
                [ngClass]="{ 'full-line-radio-button': authFlow.value !== authFlowOption.proxy }"
                [value]="authFlow.value"
                (change)="onAuthFlowChange(authFlow.value)"
                [disabled]="disableAuthFlowOption(authFlow.value)"
              >
                {{ authFlow.displayValue }}
              </mat-radio-button>
            </div>
            <div *ngIf="authFlow.value === authFlowOption.proxy && appStepperState.selectedAuthFlow === authFlowOption.proxy" class="auth-proxy-optional-form-fields-container">
              <portal-stepper-form-field
                class="radio-button-form-field"
                placeholder="Optional: Enter the logout url"
                [formCtrl]="authFlowFormGroup.get('logout_url')"
                (formBlur)="onFormBlur(authFlowFormGroup, 'logout_url', currentApplicationModel.authentication.proxy)"
                tooltip="The url the application goes to when it logs out"
              >
              </portal-stepper-form-field>
              <portal-stepper-form-field
                class="radio-button-form-field"
                label="Optional: Enter the redirect after signin path"
                placeholder="/redirect_path"
                [formCtrl]="authFlowFormGroup.get('redirect_after_signin_path')"
                (formBlur)="onFormBlur(authFlowFormGroup, 'redirect_after_signin_path', currentApplicationModel.authentication.proxy)"
                tooltip="The path to which the user will be redirected on successful signin"
              >
              </portal-stepper-form-field>
            </div>
            
          </span>
        </mat-radio-group>

        <portal-page-info
          [productGuideLink]="authenticationStepProductGuideLink"
        >
        </portal-page-info>

        <div *ngIf="showAuthFlowSubStepper()">
          <portal-application-auth-flow-stepper
            [currentApplicationModel]="currentApplicationModel"
            [authFlowFormGroup]="authFlowFormGroup"
            [authFlowOptionChanged]="authFlowOptionChanged"
            (resetAuthFlowOptionChanged)="resetAuthFlowOptionChanged()"
          >
          </portal-application-auth-flow-stepper>
        </div>

        <div class="launcher-checkbox-container">
          <mat-checkbox
            [(ngModel)]="currentApplicationModel.authentication.launch_desktop"
          >
          My application is also launched from the desktop
          </mat-checkbox>
        </div>

        <div class="stepper-nav-buttons">
          <button mat-button matStepperPrevious>Back</button>
          <button mat-button matStepperNext (click)="updateApplicationModel()">Next</button>
        </div>
      </mat-step>
    </div>

    <mat-step [completed]="isAppStepperComplete()">
      <ng-template matStepLabel>Apply</ng-template>
      <p class="descriptive-text">{{ completedApplicationText }}</p>

      <div class="summary-container">
        <h4>Summary:</h4>
        <p>Name: {{ currentApplicationModel.name }}</p>
        <p>Description: {{ currentApplicationModel.description }}</p>
        <p>Category: {{ currentApplicationModel.category }}</p>
        <div *ngIf="appStepperState.selectedFqdnAlias === fqdnAliasOption.manual">
          <p>Fully-qualified domain names: {{ getFqdnAliasesAsString() }}</p>
        </div>
        <p *ngIf="!!appStepperState.selectedAccess">My application is accessed: {{ getAccessOptionDataFromAccessOption(appStepperState.selectedAccess).displayValue }}</p>
        <div *ngIf="isAccessedOnPrem(currentApplicationModel.hosting) && !!currentApplicationModel.hosting.on_prem.upstream_services">
          <p>Upstream Services:</p>
          <div *ngFor="let upstreamService of currentApplicationModel.hosting.on_prem.upstream_services">
            <p>- hostname or IP Address: {{ upstreamService.hostname }} , port: {{ upstreamService.port }}</p>
          </div>
        </div>
        <p *ngIf="!!appStepperState.selectedAuthFlow">My application: {{ getAuthFlowOptionDataFromAuthFlowOption(appStepperState.selectedAuthFlow).displayValue }}</p>
        <div *ngIf="appStepperState.selectedAuthFlow === authFlowOption.own">
          <p *ngIf="!!appStepperState.selectedAuthMethod">My application authenticates using: {{ getAuthMethodOptionDataFromAuthMethodOption(appStepperState.selectedAuthMethod).displayValue }}</p>
          <div *ngIf="appStepperState.selectedAuthMethod === authMethodOption.saml">
            <p *ngIf="!!isAuthenticatedViaSaml(currentApplicationModel.authentication)">SAML metadata: {{ currentApplicationModel.authentication.saml.saml_metadata_file }}</p>
          </div>
          <div *ngIf="appStepperState.selectedAuthMethod === authMethodOption.oidc">
            <p *ngIf="!!isAuthenticatedViaOidc(currentApplicationModel.authentication)">Redirect uri: {{ currentApplicationModel.authentication.oidc.redirect_uri }}</p>
          </div>
        </div>
        <div *ngIf="appStepperState.selectedAuthFlow === authFlowOption.proxy">
          <p *ngIf="!!isAuthenticatedViaProxy(currentApplicationModel.authentication)">Logout url: {{ currentApplicationModel.authentication.proxy.logout_url }}</p>
        </div>
        <div *ngIf="canConfigureRuntime(currentApplicationModel)">
          <p *ngIf="!!isRuntimeEnabled(currentApplicationModel.hosting)">Image: {{ getImageValueFromModel(currentApplicationModel) }}</p>
          <div *ngIf="isUsingOwnRuntime(currentApplicationModel.hosting)">
            <p>Port: {{ currentApplicationModel.hosting.in_cloud.runtime.own.port }}</p>
          </div>
          <div *ngIf="isUsingAgilicusRuntime(currentApplicationModel.hosting)">
            <p *ngIf="currentApplicationModel.hosting.in_cloud.runtime.agilicus.bundles.length !== 0">Bundle name: {{ currentApplicationModel.hosting.in_cloud.runtime.agilicus.bundles[0].name }}</p>
          </div>
        </div>
        <div *ngIf="appStepperState.selectedAuthFlow !== authFlowOption.none">
          <p *ngIf="!!appStepperState.selectedUserAuth">My application: {{ getUserAuthOptionDataFromUserAuthOption(appStepperState.selectedUserAuth).displayValue }}</p>
        </div>
      </div>

      <portal-download-button
        buttonDescription="TEMPLATE"
        tooltipText="Click to download the information you have entered as a template"
        (downloadFile)="downloadTemplate()"
      >
      </portal-download-button>

      <portal-apply-app-model
        [currentApplicationModel]="currentApplicationModel"
        [appModelStatus]="appState.application_model_status"
        [appModelSubmissionProgressBarController]="appModelSubmissionProgressBarController"
        [stepperType]="stepperType"
        (submitModel)="submitApplicationModel($event)"
      >
      </portal-apply-app-model>

      <div class="stepper-nav-buttons">
        <button mat-button matStepperPrevious>Back</button>
      </div>
    </mat-step>

    <mat-step>
      <ng-template matStepLabel>Done</ng-template>
      <div class=user-instructions-text>
        <p>{{ getInstructionsForUser() }}</p>
      </div>

      <div class="app-url-container">
        <p>Click <a href="{{ getAppUrlForLink() }}" target="_blank">here</a> to launch your application. It may take 2-3 minutes before the application becomes available.</p>
      </div>

      <div *ngIf="!!appStatus && !!appStatus.overall_status">
        <p class="app-status-text">
          The current status of this application is: 
          <mat-icon
            class="status-icon"
            [ngClass]="{ 
              'success-color': getStatusIconColor(appStatus.overall_status) === iconColor.success,
              'intermediate-color': getStatusIconColor(appStatus.overall_status) === iconColor.intermediate,
              'warn-color': getStatusIconColor(appStatus.overall_status) === iconColor.warn
            }"
          >
          {{ getStatusIcon(appStatus.overall_status) }}
          </mat-icon> {{ capitalizeFirstLetter(appStatus.overall_status) }}
        </p>
        
      </div>

      <portal-stepper-done-config-options
        *ngIf="!!getModel().app_id"
        [resourceId]="getModel().app_id"
        [resourceName]="getModel().name"
        [resourceType]="resourceType"
        [orgId]="orgId"
        [showPermissions]="showPermissions"
      >
      </portal-stepper-done-config-options>
    </mat-step>
  </mat-vertical-stepper>
</div>
