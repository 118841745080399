import { Connector, Organisation } from '@agilicus/angular';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { AppState } from '@app/core';
import {
  ActionApiApplicationsResetNetworkModel,
  ActionApiApplicationsSubmittingNetworkModel,
} from '@app/core/api-applications/api-applications.actions';
import { ApplicationModelStatus } from '@app/core/api-applications/api-applications.models';
import {
  selectApiApplicationsNetworkModel,
  selectApiApplicationsNetworkModelStatus,
} from '@app/core/api-applications/api-applications.selectors';
import {
  getDynamicSourcePortOverrideTooltipText,
  getNetworkDescriptiveText,
  getNetworkExposeAsHostnameTooltipText,
  getNetworkHostnameTooltipText,
  getNetworkNameTooltipText,
  getNetworkOverrideIpTooltipText,
  getNetworkPortTooltipText,
  getNetworkPortRangesFromString,
  getNetworkSourcePortOverrideTooltipText,
  getNetworkSourceAddressOverrideTooltipText,
  getNetworkTlsVerifyTooltipText,
} from '@app/core/application-service-state/application-services-utils';
import { initConnectors } from '@app/core/connector-state/connector.actions';
import { selectConnectorList } from '@app/core/connector-state/connector.selectors';
import { NetworkModel } from '@app/core/models/network/network-model';
import { selectCurrentOrganisation } from '@app/core/organisations/organisations.selectors';
import { CustomValidatorsService } from '@app/core/services/custom-validators.service';
import { selectCanAdminApps } from '@app/core/user/permissions/app.selectors';
import { selectCanAdminIssuers } from '@app/core/user/permissions/issuers.selectors';
import { OrgQualifiedPermission } from '@app/core/user/permissions/permissions.selectors';
import { selectCanAdminUsers } from '@app/core/user/permissions/users.selectors';
import { select, Store } from '@ngrx/store';
import { cloneDeep } from 'lodash-es';
import { combineLatest, Observable, Subject, takeUntil } from 'rxjs';
import { delayStepperAdvanceOnSuccessfulApply, handleStateOnFirstStepSelection } from '../application-template-utils';
import { KeyTabManager } from '../key-tab-manager/key-tab-manager';
import { ProgressBarController } from '../progress-bar/progress-bar-controller';
import { ResourceType } from '../resource-type.enum';
import { StepperType } from '../stepper-type.enum';
import { capitalizeFirstLetter, getEmptyStringIfUnset, modifyDataOnFormBlur, pluralizeString } from '../utils';

@Component({
  selector: 'portal-network-stepper',
  templateUrl: './network-stepper.component.html',
  styleUrls: ['./network-stepper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NetworkStepperComponent implements OnInit, OnDestroy {
  private unsubscribe$: Subject<void> = new Subject<void>();
  public currentModel: NetworkModel;
  private modelStatus: ApplicationModelStatus;
  public hasAppsPermissions: boolean;
  public hasUsersPermissions: boolean;
  public hasIssuersPermissions: boolean;
  public connectors: Array<Connector>;
  public connectorFormGroup: FormGroup;
  public nameFormGroup: FormGroup;
  public addressFormGroup: FormGroup;
  public optionalConfigFormGroup: FormGroup;
  public currentOrg: Organisation;
  public appModelSubmissionProgressBarController: ProgressBarController = new ProgressBarController();
  public stepperType = StepperType.network;
  public hasTemplates = false;
  public completedStepperText = `Your network setup is complete (you may need to assign user permissions). Please review it below. Make any corrections needed above.`;
  public pageDescriptiveText = getNetworkDescriptiveText();
  public productGuideLink = `https://www.agilicus.com/anyx-guide/services/`;
  public orgId: string;
  public optionalConfigOptions: Array<{ name: string; value: boolean }> = [
    { name: 'Yes', value: true },
    { name: 'No', value: false },
  ];

  public capitalizeFirstLetter = capitalizeFirstLetter;
  public pluralizeString = pluralizeString;
  public getNetworkPortTooltipText = getNetworkPortTooltipText;
  public getNetworkHostnameTooltipText = getNetworkHostnameTooltipText;
  public getNetworkNameTooltipText = getNetworkNameTooltipText;
  public getNetworkOverrideIpTooltipText = getNetworkOverrideIpTooltipText;
  public getNetworkTlsVerifyTooltipText = getNetworkTlsVerifyTooltipText;
  public getNetworkExposeAsHostnameTooltipText = getNetworkExposeAsHostnameTooltipText;
  public getDynamicSourcePortOverrideTooltipText = getDynamicSourcePortOverrideTooltipText;
  public getNetworkSourcePortOverrideTooltipText = getNetworkSourcePortOverrideTooltipText;
  public getNetworkSourceAddressOverrideTooltipText = getNetworkSourceAddressOverrideTooltipText;

  public stepperState: { optionalConfig: boolean | undefined } = {
    optionalConfig: undefined,
  };
  public resourceType = ResourceType.application_service;

  public keyTabManager: KeyTabManager = new KeyTabManager();

  @ViewChild('networkStepper') public stepper: MatStepper;

  constructor(
    private formBuilder: FormBuilder,
    private store: Store<AppState>,
    private changeDetector: ChangeDetectorRef,
    private customValidatorsService: CustomValidatorsService
  ) {}

  public ngOnInit(): void {
    this.resetModel();
    this.store.dispatch(initConnectors({ force: true, blankSlate: false }));
    const hasUsersPermissions$ = this.store.pipe(select(selectCanAdminUsers));
    const hasAppsPermissions$ = this.store.pipe(select(selectCanAdminApps));
    const hasIssuersPermissions$ = this.store.pipe(select(selectCanAdminIssuers));
    const currentOrg$ = this.store.pipe(select(selectCurrentOrganisation));
    const modelState$ = this.store.pipe(select(selectApiApplicationsNetworkModel));
    const modelStatusState$ = this.store.pipe(select(selectApiApplicationsNetworkModelStatus));
    const connectorListState$ = this.store.pipe(select(selectConnectorList));
    combineLatest([
      hasUsersPermissions$,
      hasAppsPermissions$,
      hasIssuersPermissions$,
      currentOrg$,
      modelState$,
      modelStatusState$,
      connectorListState$,
    ])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        ([
          hasUsersPermissionsResp,
          hasAppsPermissionsResp,
          hasIssuersPermissionsResp,
          currentOrgResp,
          modelStateResp,
          modelStatusStateResp,
          connectorListStateResp,
        ]: [
          OrgQualifiedPermission,
          OrgQualifiedPermission,
          OrgQualifiedPermission,
          Organisation,
          NetworkModel,
          ApplicationModelStatus,
          Array<Connector>
        ]) => {
          this.orgId = hasUsersPermissionsResp?.orgId;
          this.modelStatus = modelStatusStateResp;
          if (!this.currentModel || this.modelStatus.complete) {
            this.currentModel = cloneDeep(modelStateResp);
          }
          this.hasAppsPermissions = hasAppsPermissionsResp.hasPermission;
          this.hasUsersPermissions = hasUsersPermissionsResp.hasPermission;
          this.hasIssuersPermissions = hasIssuersPermissionsResp.hasPermission;
          this.connectors = connectorListStateResp;
          this.currentOrg = currentOrgResp;
          if (!this.modelStatus.saving) {
            this.initializeFormGroups();
          }
          delayStepperAdvanceOnSuccessfulApply(this.stepper, this.modelStatus);
        }
      );
  }

  public ngOnDestroy(): void {
    this.changeDetector.detach();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private resetModel(): void {
    this.store.dispatch(new ActionApiApplicationsResetNetworkModel());
  }

  public onStepperSelectionChange(selectedIndex: number): void {
    handleStateOnFirstStepSelection(selectedIndex, this.modelStatus, undefined, this.resetModel.bind(this), undefined);
  }

  private initializeFormGroups(): void {
    const model = this.getModel();
    this.initializeConnectorFormGroup();
    this.initializeNameFormGroup(model);
    this.initializeAddressFormGroup(model);
    this.initializeOptionalConfigFormGroup(model);
    this.changeDetector.detectChanges();
  }

  private initializeConnectorFormGroup(): void {
    this.connectorFormGroup = this.formBuilder.group({
      connector_name: [getEmptyStringIfUnset(this.currentModel?.connector_name), [Validators.required]],
    });
  }

  private getModel(): NetworkModel | undefined {
    return this.currentModel;
  }

  private initializeNameFormGroup(model: NetworkModel): void {
    this.nameFormGroup = this.formBuilder.group({
      name: [
        getEmptyStringIfUnset(model?.name),
        [Validators.required, this.customValidatorsService.fqdnSingleLabelNameValidator()],
        [this.customValidatorsService.isDuplicateResourceName(this.orgId, this.modelStatus.complete)],
      ],
    });
  }

  private initializeAddressFormGroup(model: NetworkModel): void {
    this.addressFormGroup = this.formBuilder.group({
      hostname: [getEmptyStringIfUnset(model?.hostname), [Validators.required, this.customValidatorsService.hostnameOrIP4Validator()]],
      port: [getEmptyStringIfUnset(model?.config.ports[0].port), [Validators.required, this.customValidatorsService.portValidator()]],
    });
  }

  private initializeOptionalConfigFormGroup(model: NetworkModel): void {
    this.optionalConfigFormGroup = this.formBuilder.group({
      optional_config: [this.stepperState.optionalConfig, [Validators.required]],
      override_ip: [getEmptyStringIfUnset(model?.override_ip), [this.customValidatorsService.ip4AddressValidator()]],
      tls_enabled: model?.tls_enabled === undefined ? null : model?.tls_enabled,
      tls_verify: [{ value: model?.tls_verify === undefined ? null : model?.tls_verify, disabled: !model.tls_enabled }],
      expose_as_hostname: model?.expose_as_hostname === undefined ? null : model?.expose_as_hostname,
      dynamic_source_port_override:
        model?.config.dynamic_source_port_override === undefined ? null : model?.config.dynamic_source_port_override,
      source_port_override: [getEmptyStringIfUnset(model?.source_port_override), [this.customValidatorsService.portRangeValidator()]],
      source_address_override: [
        getEmptyStringIfUnset(model?.source_address_override),
        [this.customValidatorsService.hostnameOrIp4OrIpValidator()],
      ],
    });
  }

  public hasAllPermissions(): boolean {
    return !!this.hasAppsPermissions && !!this.hasUsersPermissions && !!this.hasIssuersPermissions;
  }

  public showNoPermissionsText(): boolean {
    if (this.hasAppsPermissions === undefined || this.hasUsersPermissions === undefined || this.hasIssuersPermissions === undefined) {
      return false;
    }
    if (this.hasAllPermissions()) {
      return false;
    }
    return true;
  }

  public onFormBlur<T extends object>(form: FormGroup, formField: string, obj: T): void {
    modifyDataOnFormBlur(form, formField, this.modifyStepperDataOnFormBlur.bind(this), obj);
  }

  private modifyStepperDataOnFormBlur<T extends object>(form: FormGroup, formField: string, obj: T): void {
    if (formField === 'name') {
      obj[formField] = form.get(formField).value.toLowerCase().trim();
    } else if (formField === 'port') {
      this.updateModel();
    } else {
      obj[formField] = form.get(formField).value;
    }
  }

  public updateConnector(connectorName: string): void {
    this.currentModel.connector_name = connectorName;
    this.updateModel();
  }

  private updateModel(): void {
    this.currentModel.config.ports[0].port = this.addressFormGroup.get('port').value;

    this.currentModel.config.source_port_override = getNetworkPortRangesFromString(
      this.optionalConfigFormGroup.get('source_port_override').value
    );
    this.currentModel.config.source_address_override = this.optionalConfigFormGroup.get('source_address_override').value;
  }

  public onOptionalConfigChange(isSelected: boolean): void {
    this.stepperState.optionalConfig = isSelected;
    if (!isSelected) {
      this.currentModel.override_ip = '';
      this.currentModel.tls_enabled = false;
      this.currentModel.tls_verify = false;
      this.currentModel.expose_as_hostname = false;
      this.currentModel.config.dynamic_source_port_override = false;
      this.currentModel.source_port_override = '';
      this.currentModel.source_address_override = '';
      this.optionalConfigFormGroup.get('override_ip').setValue('');
      this.optionalConfigFormGroup.get('tls_enabled').setValue(false);
      this.optionalConfigFormGroup.get('tls_verify').setValue(false);
      this.optionalConfigFormGroup.get('expose_as_hostname').setValue(false);
      this.optionalConfigFormGroup.get('dynamic_source_port_override').setValue(false);
      this.optionalConfigFormGroup.get('source_port_override').setValue('');
      this.optionalConfigFormGroup.get('source_address_override').setValue('');
    }
  }

  public onCheckboxChange(param: string, isBoxChecked: boolean): void {
    if (param === 'tls_enabled') {
      this.currentModel.tls_enabled = isBoxChecked;
      this.currentModel.tls_verify = isBoxChecked;
      const tlsVerifyFormControl = this.optionalConfigFormGroup.get('tls_verify');
      tlsVerifyFormControl.setValue(isBoxChecked);
      if (isBoxChecked) {
        tlsVerifyFormControl.enable();
      } else {
        tlsVerifyFormControl.disable();
      }
    } else if (param === 'dynamic_source_port_override') {
      this.currentModel.config.dynamic_source_port_override = isBoxChecked;
    } else {
      this.currentModel[param] = isBoxChecked;
    }
  }

  public isStepperComplete(): boolean {
    return this.modelStatus.complete;
  }

  public submitModel(model: NetworkModel): void {
    this.store.dispatch(new ActionApiApplicationsSubmittingNetworkModel(model));
  }

  public canDeactivate(): Observable<boolean> | boolean {
    this.resetModel();
    return true;
  }
}
