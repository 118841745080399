import { Label, MFAPolicyTemplate, PolicyTemplate, PolicyTemplateInstance, SourceInfoPolicyTemplate } from '@agilicus/angular';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { PolicyTemplateType } from '@app/core/api/policy-template-instance/policy-template-type';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { AppState, NotificationService } from '@app/core';
import { allowSkippingCompletedStepsOnStepperInit } from '../utils';
import { KeyTabManager } from '../key-tab-manager/key-tab-manager';
import { select, Store } from '@ngrx/store';
import { filter, map, Subject, take, takeUntil } from 'rxjs';
import { selectPolicyTemplateInstances } from '@app/core/policy-template-instance-state/policy-template-instance.selectors';
import { PolicyTemplateInstanceState } from '@app/core/policy-template-instance-state/policy-template-instance.reducer';
import { MatStepper } from '@angular/material/stepper';
import { savingPolicyTemplateInstance } from '@app/core/policy-template-instance-state/policy-template-instance.actions';
import { ChiplistInput } from '../custom-chiplist-input/chiplist-input';
import { createChiplistInput } from '../custom-chiplist-input/custom-chiplist-input.utils';
import { FilterChipOptions } from '../filter-chip-options';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { isANumber, isValidSubnet } from '../validation-utils';
import { CustomValidatorsService } from '@app/core/services/custom-validators.service';
import { getNewCrudStateObjGuid } from '@app/core/api/state-driven-crud/state-driven-crud';
import { InputData } from '../custom-chiplist-input/input-data';
import {
  getFormatedTemplateType,
  getFormatedTemplateTypeFromInstance,
  getIsoCountryCodesListAsString,
  getLabelsListAsString,
  getSourceSubnetsListAsString,
  getTemplateDescriptiveText,
  getTemplateTypeOptionData,
  getTemplateTypeOptionValueText,
} from '@app/core/api/policy-template-instance/policy-template-instance-utils';
import * as i18nIsoCountries from 'i18n-iso-countries';

export interface PolicyTemplateDialogData {
  policyTemplateInstance?: PolicyTemplateInstance;
  labelsList: Array<Label>;
  orgId: string;
}

enum PolicyTemplateInstanceSaveState {
  SAVING = 'saving',
  SUCCESS = 'success',
  FAILED = 'failed',
}

export interface MFAPolicyTemplateElement extends MFAPolicyTemplate, InputData {}

export interface SourceInfoPolicyTemplateElement extends SourceInfoPolicyTemplate, InputData {}

@Component({
  selector: 'portal-policy-template-dialog',
  templateUrl: './policy-template-dialog.component.html',
  styleUrls: ['./policy-template-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PolicyTemplateDialogComponent implements OnInit, OnDestroy {
  private unsubscribe$: Subject<void> = new Subject<void>();
  public allForms: UntypedFormGroup;
  public templateTypeForm: UntypedFormGroup;
  public nameForm: UntypedFormGroup;
  public mfaConfigForm: UntypedFormGroup;
  public deviceInfoConfigForm: UntypedFormGroup;
  public optionalConfigForm: UntypedFormGroup;
  private orgId: string;
  public templateTypeOptionData = getTemplateTypeOptionData();
  public creatingNewTemplate = true;
  public policyTemplateInstanceToUpdate = this.getBlankPolicyTemplateInstance();
  public addDisabled = false;
  private labelsList: Array<Label> = [];
  public labelsChiplistInput: ChiplistInput<object>;
  public isoCountryCodesChiplistInput: ChiplistInput<object>;
  public sourceSubnetsChiplistInput: ChiplistInput<object>;
  public mfaPolicyTemplateElement: MFAPolicyTemplateElement = {
    labels: [],
    seconds_since_last_challenge: undefined,
    template_type: PolicyTemplateType.mfa,
  };
  public sourceInfoPolicyTemplateElement: SourceInfoPolicyTemplateElement = {
    labels: [],
    template_type: PolicyTemplateType.source_info,
    source_subnets: [],
    iso_country_codes: [],
    invert: false,
    action: SourceInfoPolicyTemplate.ActionEnum.deny,
    log_message: '',
  };
  public filterChipOptions: FilterChipOptions = {
    visible: true,
    selectable: true,
    removable: true,
    addOnBlur: true,
    separatorKeysCodes: [ENTER, COMMA],
  };
  public invertFalseValue = false;
  public invertTrueValue = true;

  // For setting enter key to change input focus.
  public keyTabManager: KeyTabManager = new KeyTabManager();

  // This is required in order to reference the enums in the html template.
  public policyTemplateType = PolicyTemplateType;

  public getFormatedTemplateType = getFormatedTemplateType;
  public getLabelsListAsString = getLabelsListAsString;
  public getTemplateTypeOptionValueText = getTemplateTypeOptionValueText;
  public getSourceSubnetsListAsString = getSourceSubnetsListAsString;
  public getIsoCountryCodesListAsString = getIsoCountryCodesListAsString;

  @ViewChild('stepper') public stepper: MatStepper;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: PolicyTemplateDialogData,
    public dialogRef: MatDialogRef<PolicyTemplateDialogComponent>,
    private formBuilder: UntypedFormBuilder,
    private notificationService: NotificationService,
    private changeDetector: ChangeDetectorRef,
    private store: Store<AppState>,
    private customValidatorsService: CustomValidatorsService
  ) {
    if (data) {
      this.orgId = data.orgId;
      this.policyTemplateInstanceToUpdate.spec.org_id = this.orgId;
      this.labelsList = data.labelsList;
      if (!!data.policyTemplateInstance) {
        this.creatingNewTemplate = false;
        this.policyTemplateInstanceToUpdate = data.policyTemplateInstance;
      }
    }
  }

  public setAllMatStepsAsInteracted() {
    if (this.creatingNewTemplate === false) {
      // This allows skipping to the "Apply" step rather than having to
      // click through each already completed step when editing an existing policy
      allowSkippingCompletedStepsOnStepperInit(this.stepper);
    }
  }

  private getBlankPolicyTemplateInstance(): PolicyTemplateInstance {
    return {
      spec: {
        org_id: this.orgId,
        template: undefined,
        description: '',
        name: '',
      },
      metadata: {
        id: getNewCrudStateObjGuid(),
      },
    };
  }

  public ngOnInit(): void {
    i18nIsoCountries.registerLocale(require('i18n-iso-countries/langs/en.json'));
    this.setTemplateDataFromExisting();
    this.setAllFormsData();
    this.labelsChiplistInput = this.getLabelsChiplistInput();
    this.isoCountryCodesChiplistInput = this.getIsoCountryCodesChiplistInput();
    this.sourceSubnetsChiplistInput = this.getSourceSubnetsChiplistInput();
    if (!this.creatingNewTemplate) {
      this.setFormDataFromExisting();
    }
  }

  private setAllFormsData(): void {
    this.initializeForms();
    this.allForms = this.formBuilder.group({
      templateTypeForm: this.templateTypeForm,
      nameForm: this.nameForm,
      mfaConfigForm: this.mfaConfigForm,
      deviceInfoConfigForm: this.deviceInfoConfigForm,
      optionalConfigForm: this.optionalConfigForm,
    });
  }

  public ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.changeDetector.detach();
  }

  private setTemplateDataFromExisting(): void {
    if (this.creatingNewTemplate) {
      return;
    }
    const templateType = this.policyTemplateInstanceToUpdate.spec.template.template_type;
    if (templateType === PolicyTemplateType.mfa) {
      this.mfaPolicyTemplateElement = this.policyTemplateInstanceToUpdate.spec.template as MFAPolicyTemplate;
    }
    if (templateType === PolicyTemplateType.source_info) {
      this.sourceInfoPolicyTemplateElement = this.policyTemplateInstanceToUpdate.spec.template as SourceInfoPolicyTemplate;
    }
    // TODO: will add more template types here:
  }

  private setFormDataFromExisting(): void {
    if (this.creatingNewTemplate) {
      return;
    }
    const templateTypeFormControl = this.templateTypeForm.get('template_type');
    const currentTemplateType = this.policyTemplateInstanceToUpdate.spec.template.template_type as PolicyTemplateType;
    templateTypeFormControl.setValue(currentTemplateType);
    this.onTemplateTypeSelection(currentTemplateType);
  }

  public getNameFromInstance(): string | undefined {
    return this.policyTemplateInstanceToUpdate?.spec?.name;
  }

  public getDescriptionFromInstance(): string | undefined {
    return this.policyTemplateInstanceToUpdate?.spec?.description;
  }

  public getSelectedTemplateTypeDescriptiveText(): string {
    const templateType = this.getSelectedTemplateTypeFromForm();
    return getTemplateDescriptiveText(templateType);
  }

  public getConfigureTemplateTitle(): string {
    return `Configure "${getFormatedTemplateTypeFromInstance(this.policyTemplateInstanceToUpdate)}" policy "${this.getNameFromInstance()}"`;
  }

  private initializeForms(): void {
    this.initializeTemplateTypeForm();
    this.initializeNameForm();
    this.initializeMfaConfigForm();
    this.initializeDeviceInfoConfigForm();
    this.initializeOptionalConfigForm();
  }

  private initializeTemplateTypeForm(): void {
    this.templateTypeForm = this.formBuilder.group({
      template_type: ['', [Validators.required]],
    });
  }

  public getSelectedTemplateTypeFromForm(): string {
    return !!this.templateTypeForm ? this.templateTypeForm.get('template_type')?.value : '';
  }

  private initializeNameForm(): void {
    this.nameForm = this.formBuilder.group({
      name: [this.policyTemplateInstanceToUpdate?.spec?.name ? this.policyTemplateInstanceToUpdate.spec.name : '', [Validators.required]],
    });
  }

  public getNameValueFromForm(): string {
    return !!this.nameForm ? this.nameForm.get('name')?.value : '';
  }

  private getDurationBasicValidators(): Array<ValidatorFn> {
    return [this.customValidatorsService.customValidator(isANumber)];
  }

  private initializeMfaConfigForm(): void {
    this.mfaConfigForm = this.formBuilder.group({
      duration: [
        this.mfaPolicyTemplateElement?.seconds_since_last_challenge ? this.mfaPolicyTemplateElement.seconds_since_last_challenge : '',
        [...this.getDurationBasicValidators()],
      ],
    });
  }

  public getDurationValueFromForm(): string {
    return !!this.mfaConfigForm ? this.mfaConfigForm.get('duration')?.value : '';
  }

  public hasAtLeastOneLabel(): boolean {
    const templateType = this.getSelectedTemplateTypeFromForm();
    if (templateType === PolicyTemplateType.mfa) {
      return this.mfaPolicyTemplateElement.labels.length !== 0;
    }
    if (templateType === PolicyTemplateType.source_info) {
      return this.sourceInfoPolicyTemplateElement.labels.length !== 0;
    }
    // TODO: will add more template types here:
    return false;
  }

  private initializeDeviceInfoConfigForm(): void {
    this.deviceInfoConfigForm = this.formBuilder.group({
      log_message: !!this.sourceInfoPolicyTemplateElement?.log_message ? this.sourceInfoPolicyTemplateElement.log_message : '',
      invert: [
        this.sourceInfoPolicyTemplateElement?.invert !== undefined ? this.sourceInfoPolicyTemplateElement.invert : false,
        [Validators.required],
      ],
      // This is the input used to enter chip values, not the chip values themselves:
      source_subnets_value: '',
      // This is the input used to enter chip values, not the chip values themselves:
      iso_country_codes_value: '',
    });
  }

  public getLogMessageValueFromForm(): string {
    return !!this.deviceInfoConfigForm ? this.deviceInfoConfigForm.get('log_message')?.value : '';
  }

  public getInvertValueFromForm(): boolean {
    return !!this.deviceInfoConfigForm ? this.deviceInfoConfigForm.get('invert')?.value : false;
  }

  private initializeOptionalConfigForm(): void {
    this.optionalConfigForm = this.formBuilder.group({
      description: !!this.policyTemplateInstanceToUpdate?.spec?.description ? this.policyTemplateInstanceToUpdate.spec.description : '',
      // This is the input used to enter chip values, not the chip values themselves:
      label_input_value: '',
    });
  }

  public getDescriptionValueFromForm(): string {
    return !!this.optionalConfigForm ? this.optionalConfigForm.get('description')?.value : '';
  }

  private getLabelsChiplistInput(): ChiplistInput<object> {
    const chiplistInput = createChiplistInput('labels');
    chiplistInput.allowedValues = this.labelsList;
    chiplistInput.hasAutocomplete = true;
    chiplistInput.formControl = this.optionalConfigForm.get('label_input_value') as UntypedFormControl;
    chiplistInput.getDisplayValue = (item: Label | string) => {
      const itemAsLabel = item as Label;
      if (!!itemAsLabel.metadata) {
        return !!itemAsLabel?.spec?.name ? itemAsLabel.spec.name : '';
      }
      const itemAsString = item as string;
      return itemAsString;
    };
    return chiplistInput;
  }

  private getSourceSubnetsChiplistInput(): ChiplistInput<object> {
    const chiplistInput = createChiplistInput('source_subnets');
    chiplistInput.hasAutocomplete = false;
    chiplistInput.isFreeform = true;
    chiplistInput.formControl = this.deviceInfoConfigForm.get('source_subnets_value') as UntypedFormControl;
    chiplistInput.isValidEntry = (subnet: string): boolean => {
      return isValidSubnet(subnet);
    };
    return chiplistInput;
  }

  private getIsoCountryCodesChiplistInput(): ChiplistInput<object> {
    const chiplistInput = createChiplistInput('iso_country_codes');
    chiplistInput.displayName = 'Country Codes';
    chiplistInput.allowedValues = Object.keys(i18nIsoCountries.getAlpha2Codes());
    chiplistInput.hasAutocomplete = true;
    chiplistInput.formControl = this.deviceInfoConfigForm.get('iso_country_codes_value') as UntypedFormControl;
    return chiplistInput;
  }

  public onCancelClick(): void {
    this.dialogRef.close(false);
  }

  public getTemplateTypeSelectionTooltipText(): string {
    return `The type of policy to be configured. This selection determines the data that will need to be collected in subsequent steps.`;
  }

  public enableForms(): void {
    this.addDisabled = false;
    this.allForms.enable();
  }

  public disableForms(): void {
    this.addDisabled = true;
    this.allForms.disable();
  }

  private setPolicyTemplateInstanceFromForms(): void {
    const templateType = this.getSelectedTemplateTypeFromForm();
    if (templateType === PolicyTemplateType.mfa) {
      const duration = this.getDurationValueFromForm();
      this.mfaPolicyTemplateElement.seconds_since_last_challenge = parseInt(duration, 10);
      // This gets added via the chiplist input component, so need to remove:
      delete this.mfaPolicyTemplateElement.dirty;
      this.policyTemplateInstanceToUpdate.spec.template = this.mfaPolicyTemplateElement;
    }
    if (templateType === PolicyTemplateType.source_info) {
      this.sourceInfoPolicyTemplateElement.log_message = this.getLogMessageValueFromForm();
      this.sourceInfoPolicyTemplateElement.invert = this.getInvertValueFromForm();
      // This gets added via the chiplist input component, so need to remove:
      delete this.sourceInfoPolicyTemplateElement.dirty;
      delete this.sourceInfoPolicyTemplateElement.isValid;
      delete this.sourceInfoPolicyTemplateElement.resetIsValid;
      this.policyTemplateInstanceToUpdate.spec.template = this.sourceInfoPolicyTemplateElement;
    }
    const name = this.getNameValueFromForm();
    const description = this.getDescriptionValueFromForm();
    this.policyTemplateInstanceToUpdate.spec.name = name;
    this.policyTemplateInstanceToUpdate.spec.description = description;
  }

  private checkSaveStatus(): void {
    this.store
      .pipe(select(selectPolicyTemplateInstances))
      .pipe(
        takeUntil(this.unsubscribe$),
        map((state: PolicyTemplateInstanceState) => {
          if (state.saving_state) {
            return PolicyTemplateInstanceSaveState.SAVING;
          }
          if (!state.state_save_success) {
            // save was unsuccessful
            return PolicyTemplateInstanceSaveState.FAILED;
          }
          // save was successful
          return PolicyTemplateInstanceSaveState.SUCCESS;
        }),
        filter((action) => action !== PolicyTemplateInstanceSaveState.SAVING),
        take(1)
      )
      .subscribe((action) => {
        if (action === PolicyTemplateInstanceSaveState.FAILED) {
          // re-enable form to retry saving
          this.enableForms();
        } else {
          setTimeout(() => {
            this.dialogRef.close(true);
          }, 2000);
        }
      });
  }

  public savePolicyTemplate(): void {
    this.setPolicyTemplateInstanceFromForms();
    this.store.dispatch(
      savingPolicyTemplateInstance({ obj: this.policyTemplateInstanceToUpdate, trigger_update_side_effects: false, notifyUser: true })
    );
    this.disableForms();
    // check if save is successful
    this.checkSaveStatus();
  }

  public removeLabelChip(chipValue: string): void {
    const templateType = this.getSelectedTemplateTypeFromForm();
    if (templateType === PolicyTemplateType.mfa) {
      this.mfaPolicyTemplateElement.labels = this.mfaPolicyTemplateElement.labels.filter((label) => label !== chipValue);
    }
    if (templateType === PolicyTemplateType.source_info) {
      this.sourceInfoPolicyTemplateElement.labels = this.sourceInfoPolicyTemplateElement.labels.filter((label) => label !== chipValue);
    }
  }

  public removeSourceSubnetsChip(chipValue: string): void {
    this.sourceInfoPolicyTemplateElement.source_subnets = this.sourceInfoPolicyTemplateElement.source_subnets.filter(
      (value) => value !== chipValue
    );
  }

  public removeIsoCountryCodesChip(chipValue: string): void {
    this.sourceInfoPolicyTemplateElement.iso_country_codes = this.sourceInfoPolicyTemplateElement.iso_country_codes.filter(
      (value) => value !== chipValue
    );
  }

  public getPolicyTemplateLabelsTooltipText(): string {
    return `The policy will apply to resources with these labels`;
  }

  public getPolicyTemplateMfaDurationTooltipText(): string {
    return `Challenge the user if they have not presented a second factor for the current session in the last N seconds.`;
  }

  public getPolicyTemplateSourceSubnetsTooltipText(): string {
    return `A list of IP subnets. If the request comes from one of them, it will be allowed or denied based on the provided configuration.`;
  }

  public getPolicyTemplateIsoCountryCodesTooltipText(): string {
    return `A list of ISO 3166-1 alpha-2 country codes. If the request comes from one of them, it will be allowed or denied based on the provided configuration.`;
  }

  public getLabelsListAsStringFromTemplate(): string {
    const templateType = this.getSelectedTemplateTypeFromForm();
    if (templateType === PolicyTemplateType.mfa) {
      return getLabelsListAsString(this.mfaPolicyTemplateElement);
    }
    if (templateType === PolicyTemplateType.source_info) {
      return getLabelsListAsString(this.sourceInfoPolicyTemplateElement);
    }
    return '';
  }

  public canAssignLabels(templateType: string): boolean {
    // TODO: will add more template types here:
    if (templateType === PolicyTemplateType.mfa || templateType === PolicyTemplateType.source_info) {
      return true;
    }
    return false;
  }

  public getTemplateForChiplists(): PolicyTemplate {
    const templateType = this.getSelectedTemplateTypeFromForm();
    if (templateType === PolicyTemplateType.mfa) {
      return this.mfaPolicyTemplateElement;
    }
    if (templateType === PolicyTemplateType.source_info) {
      return this.sourceInfoPolicyTemplateElement;
    }
    // TODO: will add more template types here:
    return undefined;
  }

  public onTemplateTypeSelection(templateType: PolicyTemplateType): void {
    const mfaConfigFormControl = this.mfaConfigForm.get('duration');
    if (templateType === PolicyTemplateType.mfa) {
      mfaConfigFormControl.setValidators([...this.getDurationBasicValidators(), Validators.required]);
    } else {
      mfaConfigFormControl.setValidators([...this.getDurationBasicValidators()]);
    }
    // TODO: will add more template types here:
  }

  public getTemplateTypeDescriptiveText(templateType: string): string {
    const templateDescriptiveText = getTemplateDescriptiveText(templateType);
    if (!templateDescriptiveText) {
      return 'Select a policy type for more details';
    }
    return templateDescriptiveText;
  }

  public getTemplateDescriptiveTextForStepper(): string {
    const templateType = this.getSelectedTemplateTypeFromForm();
    return this.getTemplateTypeDescriptiveText(templateType);
  }

  public onInvertOptionChange(invertOptionValue: boolean): void {
    const formControl = this.deviceInfoConfigForm.get('invert');
    formControl.setValue(invertOptionValue);
  }
}
