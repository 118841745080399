import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  OnDestroy,
  Renderer2,
  ChangeDetectorRef,
  Output,
  EventEmitter,
  Input,
} from '@angular/core';
import { Subject, Observable, Observer, of, forkJoin, EMPTY } from 'rxjs';
import { Papa, UnparseConfig } from 'ngx-papaparse';
import { Store, select } from '@ngrx/store';
import { AppState, NotificationService } from '@app/core';
import { takeUntil, map, catchError } from 'rxjs/operators';
import { UsersToGroupsService, UserWithDetail } from '@app/core/api/users-to-groups/users-to-groups.service';

import { User } from '@agilicus/angular';
import { ProgressBarController } from '../progress-bar/progress-bar-controller';
import { selectApiOrgId } from '@app/core/user/user.selectors';
import { uploadIsCsv, getFile } from '../file-utils';
import { UserAdminComponent } from '../user-admin/user-admin.component';
import { checkIfValidUserEmail } from '../validation-utils';
import { MapObject, UnparseData } from 'ngx-papaparse/lib/interfaces/unparse-data';
import { addRowNumbers, CsvData, removeInvalidColumns, removeWhitespace, UploadStatus } from '../csv-utils';

export interface UploadedUser extends User, CsvData {
  groups?: string;
  user?: UploadedUser;
  description?: string;
}

export interface UploadResult {
  status: UploadStatus;
}

@Component({
  selector: 'portal-users-csv',
  templateUrl: './users-csv.component.html',
  styleUrls: ['./users-csv.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UsersCsvComponent implements OnInit, OnDestroy {
  @Input() public tableData: Array<any> = [];
  @Input() public firstNameToGroupMap: Map<string, User> = new Map();
  @Input() public emailToUserMap: Map<string, User> = new Map();
  @Input() public preventUpload = false;
  @Input() public disableUpload = false;
  @Input() public userLimit = undefined;
  @Output() private updateEvent = new EventEmitter<any>();
  @Output() private uploadClick = new EventEmitter<any>();
  @Output() private notifyUser = new EventEmitter<any>();
  public allUsers = new Observable<Array<UserWithDetail>>();
  private unsubscribe$: Subject<void> = new Subject<void>();
  private org_id$: Observable<string>;
  private org_id: string;
  public buttonDescription = 'USERS';
  public uploadButtonTooltipText =
    'Upload a csv file in format "email", "first_name", "last_name", "external_id", "description", "groups" with group names separated by a semicolon';
  public isUploading = false;

  public validHeaders = new Set(['email', 'first_name', 'last_name', 'external_id', 'description', 'groups']);

  public progressBarController: ProgressBarController = new ProgressBarController();

  constructor(
    private store: Store<AppState>,
    private changeDetector: ChangeDetectorRef,
    private notificationService: NotificationService,
    private usersToGroupsService: UsersToGroupsService,
    private papa: Papa,
    private renderer: Renderer2,
    public userAdmin: UserAdminComponent
  ) {}

  public ngOnInit(): void {
    this.org_id$ = this.store.pipe(select(selectApiOrgId));
    this.org_id$.pipe(takeUntil(this.unsubscribe$)).subscribe((resp) => {
      this.org_id = resp;
    });
  }

  public ngOnDestroy(): void {
    this.changeDetector.detach();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  /**
   * Parses the semicolon separated string of group names into
   * an array of existing group objects and a set of new group names.
   * The array of group objects is then assigned to the user's 'member_of' property.
   */
  private parseGroups(user: UploadedUser, newGroupsSet: Set<string>, usersWithInvalidGroups: Array<UploadedUser>): void {
    if (user.groups === '') {
      user.member_of = [];
      return;
    }
    if (user.description) {
      if (!user.inheritable_config) {
        user.inheritable_config = {};
      }
      user.inheritable_config.description = user.description;
    }
    const groupNamesArray = user.groups.split(';').map((group) => group.trim());
    const existingGroupsArray = [];
    groupNamesArray.forEach((group) => {
      if (group !== '') {
        const fullGroupObject = this.firstNameToGroupMap.get(group);
        if (fullGroupObject !== undefined) {
          existingGroupsArray.push(fullGroupObject);
        } else {
          newGroupsSet.add(group);
          usersWithInvalidGroups.push(user);
        }
      }
    });
    user.member_of = existingGroupsArray;
    return;
  }

  private setUploadedUserProperties(user: UploadedUser): void {
    user.org_id = this.org_id;
  }

  private checkValidUpload(user: UploadedUser): boolean {
    if (!checkIfValidUserEmail(user)) {
      return false;
    }
    return true;
  }

  public onReadUsers(event: any): void {
    // Need to reassign the progressBarController in order to
    // trigger the update in the template.
    this.progressBarController = this.progressBarController.resetProgressBar();
    // build emailToUserMap
    this.emailToUserMap.clear();
    this.allUsers = this.userAdmin.getAllUsers();
    this.allUsers.pipe(takeUntil(this.unsubscribe$)).subscribe(
      (users) => {
        for (const user of users) {
          this.emailToUserMap.set(user.email, user);
          this.usersToGroupsService.idToUserMap.set(user.id, user);
        }
        const readUsersObservable$ = this.readUsers(event);
        if (readUsersObservable$ === undefined) {
          return;
        }
        readUsersObservable$.forEach((next) => {});
      },
      (error) => {
        this.notificationService.error('Failed to build email to users map. Please try again.');
      }
    );
  }

  private checkDuplicatedUser(targetUser: UploadedUser, validUsers: Array<UploadedUser>): boolean {
    let count = 0;
    for (const user of validUsers) {
      if (user.email.toLowerCase() === targetUser.email.toLowerCase()) {
        count++;
      }
    }
    if (count > 1) {
      return true;
    }
    return false;
  }

  private setValidAndInvalidUsers(
    csvParseResult: Array<UploadedUser>,
    validUsers: Array<UploadedUser>,
    invalidUsers: Array<UploadedUser>
  ): void {
    for (const user of csvParseResult) {
      if (this.checkValidUpload(user)) {
        this.setUploadedUserProperties(user);
        validUsers.push(user);
      } else {
        invalidUsers.push(user);
      }
    }
  }

  private getDuplicatedUsers(validUsers: Array<UploadedUser>): Array<UploadedUser> {
    const duplicatedUsers: Array<UploadedUser> = [];
    for (const user of validUsers) {
      if (this.checkDuplicatedUser(user, validUsers)) {
        duplicatedUsers.push(user);
      }
    }
    return duplicatedUsers;
  }

  private checkForUndefinedEntries(csvParseResult: Array<UploadedUser>): boolean {
    for (const user of csvParseResult) {
      if (
        user.email === undefined ||
        user.first_name === undefined ||
        user.last_name === undefined ||
        user.external_id === undefined ||
        user.groups === undefined
      ) {
        this.notificationService.error('The following CSV row is missing a field: ' + user.csvRowNumber);
        return true;
      }
    }
    return false;
  }

  /**
   * Searches for invalid/duplicated users, as well as non-existent groups.
   * If any are found, the user is notified and the upload is canceled.
   * If all users are valid they are uploaded via http requests.
   */
  private parseUsersToUpload(csvParseResult: Array<UploadedUser>, observer: Observer<UploadStatus>): void {
    const updatedCsvParseResult = removeInvalidColumns(csvParseResult, this.validHeaders);
    addRowNumbers(updatedCsvParseResult);
    if (this.checkForUndefinedEntries(updatedCsvParseResult)) {
      observer.complete();
      return;
    }
    const newGroupsSet: Set<string> = new Set();
    const usersWithInvalidGroups: Array<UploadedUser> = [];
    for (const user of updatedCsvParseResult) {
      removeWhitespace(user);
      this.parseGroups(user, newGroupsSet, usersWithInvalidGroups);
    }
    const invalidGroupRows = new Set(usersWithInvalidGroups.map((user) => user.csvRowNumber));
    const validUsers: Array<UploadedUser> = [];
    const invalidUsers: Array<UploadedUser> = [];
    this.setValidAndInvalidUsers(updatedCsvParseResult, validUsers, invalidUsers);
    const duplicatedUsers = this.getDuplicatedUsers(validUsers);
    if (invalidUsers.length === 0 && duplicatedUsers.length === 0 && usersWithInvalidGroups.length === 0) {
      this.uploadUsers(updatedCsvParseResult, observer);
    } else {
      this.createUploadErrorNotification(invalidUsers, duplicatedUsers, invalidGroupRows);
      observer.complete();
      this.isUploading = false;
    }
  }

  /**
   * Parses the csv into JSON to be submitted to the api via http requests.
   */
  public readUsers(event: any): Observable<unknown> {
    const uploadContent = getFile(event);
    if (uploadContent === undefined) {
      return EMPTY;
    }
    if (!uploadIsCsv(uploadContent)) {
      this.notificationService.error(
        'This file does not appear to be in CSV format. Please upload a CSV file or rename the file with ".csv" extension.' +
          ' File is of type "' +
          uploadContent.type +
          '". Expected type "text/csv".'
      );
      return EMPTY;
    }
    const papaObservable$ = new Observable((observer) => {
      this.papa.parse(uploadContent, {
        complete: (result) => {
          if (result.data.length === 0) {
            this.notificationService.error('No users to upload');
            observer.complete();
            this.isUploading = false;
            return;
          }
          this.parseUsersToUpload(result.data, observer);
        },
        error: (error) => {
          this.notificationService.error('Failed to read file. ' + error.message);
        },
        header: true,
        transformHeader: (result) => {
          const strArr = result.trim().split(/[\ ]+/);
          const newHeader = strArr.join('_').toLowerCase();
          return newHeader;
        },
        skipEmptyLines: 'greedy',
      });
    });
    return papaObservable$;
  }

  private prepareUsersToUpload$(users: Array<UploadedUser>): Array<Observable<User>> {
    const totalUsersToUpload = users.length;
    let uploadsComplete = 0;
    const observablesArray = [];
    for (const user of users) {
      // Check if user exists
      const currentUser = this.emailToUserMap.get(user.email);
      if (currentUser === undefined) {
        observablesArray.push(
          this.usersToGroupsService.post_users_to_groups(user).pipe(
            map((resp) => {
              uploadsComplete++;
              // Need to reassign the progressBarController in order to
              // trigger the update in the template.
              this.progressBarController = this.progressBarController.updateProgressBarValue(totalUsersToUpload, uploadsComplete);
              this.changeDetector.detectChanges();
              return user;
            }),
            catchError((err) => {
              return of({ user, error: err });
            })
          )
        );
      } else {
        const new_user: User = {
          id: currentUser.id,
          ...user,
        };
        observablesArray.push(
          this.usersToGroupsService.put_users_to_groups(new_user).pipe(
            map((resp) => {
              uploadsComplete++;
              // Need to reassign the progressBarController in order to
              // trigger the update in the template.
              this.progressBarController = this.progressBarController.updateProgressBarValue(totalUsersToUpload, uploadsComplete);
              this.changeDetector.detectChanges();
              return new_user;
            }),
            catchError((err) => {
              return of({ user: new_user, error: err });
            })
          )
        );
      }
    }
    return observablesArray;
  }

  private createNotificationStringFromObject(users: Array<UploadedUser>, displayValue: string): string {
    return users.map((user) => user[displayValue]).join('; ');
  }

  private createUploadErrorNotification(
    invalidUsers: Array<UploadedUser>,
    duplicatedUsers: Array<UploadedUser>,
    invalidGroupRows: Set<number>
  ): void {
    let message = '';
    if (invalidUsers.length > 0) {
      message += 'The following CSV rows are invalid: "' + this.createNotificationStringFromObject(invalidUsers, 'csvRowNumber') + '" ';
    }
    if (duplicatedUsers.length > 0) {
      message +=
        'The following CSV rows contain duplicated users: "' +
        this.createNotificationStringFromObject(duplicatedUsers, 'csvRowNumber') +
        '" ';
    }
    if (invalidGroupRows.size > 0) {
      message += 'The following CSV rows contain groups that do not exist: "' + Array.from(invalidGroupRows).join('; ') + '"';
    }
    this.notificationService.error(message);
  }

  private joinUserObservables(observablesArray: Array<Observable<UploadedUser>>, observer: Observer<UploadStatus>): void {
    forkJoin(observablesArray)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (respArray) => {
          const failedUploads = [];
          respArray.forEach((resp) => {
            // If the response is not an error it will not have an 'error' property
            // and, therefore, resp.error will be undefined for successful responses
            if (resp.error !== undefined) {
              observer.next(UploadStatus.FAIL);
              failedUploads.push(resp.user);
            } else {
              observer.next(UploadStatus.PASS);
            }
          });
          if (failedUploads.length === 0) {
            this.notificationService.success('All users successfully uploaded');
            this.delayHideProgressBar();
          } else {
            this.notificationService.error(
              'The following users failed to upload: "' + this.createNotificationStringFromObject(failedUploads, 'email') + '"'
            );
            // Stop buffering when uploads fail.
            // Need to reassign the progressBarController in order to
            // trigger the update in the template.
            this.progressBarController = this.progressBarController.onFailedUpload();
            this.changeDetector.detectChanges();
          }
        },
        (errorResp) => {
          this.notificationService.error('There was an error uploading the file');
        },
        () => {
          this.updateEvent.emit();
          observer.complete();
          this.isUploading = false;
        }
      );
  }

  private uploadUsers(users: Array<UploadedUser>, observer: Observer<UploadStatus>): void {
    this.isUploading = true;
    // Need to reassign the progressBarController in order to
    // trigger the update in the template.
    this.progressBarController = this.progressBarController.initializeProgressBar();
    this.changeDetector.detectChanges();
    const totalUsersToUpload = users.length;
    if (this.userLimit !== undefined && totalUsersToUpload > this.userLimit) {
      observer.complete();
      this.isUploading = false;
      this.progressBarController = this.progressBarController.onFailedUpload();
      this.onNotifyUserFunc();
      return;
    }
    const observablesArray$ = this.prepareUsersToUpload$(users);
    this.joinUserObservables(observablesArray$, observer);
  }

  private getUsersToDownload(data: Array<UserWithDetail>): UnparseData {
    const downloadedUsers: Array<Array<string>> = [];
    const filteredUsers: Array<User> = data.filter((user) => user.type === User.TypeEnum.user);
    const fields: string[] = ['email', 'first_name', 'last_name', 'external_id', 'description', 'groups'];
    for (const user of filteredUsers) {
      const targetUser = [
        user.email,
        user.first_name,
        user.last_name,
        user.external_id,
        user.inheritable_config?.description,
        user.member_of.map((group) => group.first_name).join(';'),
      ];
      downloadedUsers.push(targetUser);
    }
    const mapObject: MapObject = {
      fields: fields,
      data: downloadedUsers,
    };
    return mapObject;
  }

  public unparseDataToCsv(data: Array<UserWithDetail>): string {
    const downloadedUsers = this.getUsersToDownload(data);
    const options: UnparseConfig = {
      quotes: true,
      header: true,
      newline: '\n',
    };
    return this.papa.unparse(downloadedUsers, options);
  }

  public downloadUsers(): void {
    this.allUsers = this.userAdmin.getAllUsers();
    this.allUsers.pipe(takeUntil(this.unsubscribe$)).subscribe(
      (users) => {
        const usersCsv = this.unparseDataToCsv(users);
        const link = this.renderer.createElement('a');
        const blob = new Blob([usersCsv], { type: 'text/csv' });
        link.href = window.URL.createObjectURL(blob);
        link.download = 'users_data.csv';
        link.click();
      },
      (error) => {
        this.notificationService.error('Failed to download users. Please try again.');
      }
    );
  }

  /**
   * Delay hiding the progress bar by 2 seconds to match the successful
   * upload notification
   */
  public delayHideProgressBar(): void {
    setTimeout(() => {
      // Need to reassign the progressBarController in order to
      // trigger the update in the template.
      this.progressBarController = this.progressBarController.resetProgressBar();
      this.changeDetector.detectChanges();
    }, this.progressBarController.hideProgressBarDelay);
  }

  public onUploadClickFunc(): void {
    this.uploadClick.emit();
  }

  public onNotifyUserFunc(): void {
    this.notifyUser.emit();
  }
}
