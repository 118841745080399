<span *ngFor="let menuItem of navMenuItems">
  <!-- Inner most nested menu item. It has no children. -->
  <span *ngIf="!menuItem.items || menuItem.items.length === 0">
    <a
      *ngIf="menuHasAllPermissions(menuItem)"
      mat-list-item 
      routerLink="{{ menuItem.routerLink }}" 
      (click)="updateCurrentLink(menuItem.routerLink)"
      [queryParams]="{ org_id: org_id }"
      [ngClass]="{ 'active': isLinkActive(menuItem.routerLink) }"
      class="side-menu-option"
      style="--left-pad: {{ getLeftPadValue() }}"
      [tourAnchor]="menuItem.routerLink"
    >
      <mat-icon class="sidenav-icon" *ngIf="menuItem.icon">{{ menuItem.icon }}</mat-icon>
      {{ menuItem.name }}
    </a>
    <span
      *ngIf="!menuHasAllPermissions(menuItem)"  
      mat-list-item 
      class="side-menu-option is-disabled"
      style="--left-pad: {{ getLeftPadValue(true) }}"
    >
      <mat-icon class="sidenav-icon" *ngIf="menuItem.icon">{{ menuItem.icon }}</mat-icon>
      {{ menuItem.name }}
    </span>
  </span>

  <!-- Parent menu item. It has children -->
  <span *ngIf="!!menuItem.items && menuItem.items.length !== 0">
    <a
      *ngIf="menuHasAllPermissions(menuItem)"
      mat-list-item
      (click)="toggleMenu(menuItem)"
      [ngClass]="{ 'active': isChildRouteSelected(menuItem.items) && !menuItem.showMenu }"
      class="side-menu-option"
      style="--left-pad: {{ getLeftPadValue() }}"
    >
      <mat-icon class="sidenav-icon" *ngIf="menuItem.icon">{{ menuItem.icon }}</mat-icon>
      {{ menuItem.name }}
      <mat-icon *ngIf="!menuItem.showMenu">chevron_right</mat-icon>
      <mat-icon *ngIf="menuItem.showMenu">expand_more</mat-icon>
    </a>

    <span
      *ngIf="!menuHasAllPermissions(menuItem)"
      mat-list-item
      class="side-menu-option is-disabled"
      style="--left-pad: {{ getLeftPadValue(true) }}"
    >
      <span [ngClass]="{ 'disabled-parent-menu': nestLevel === 0 }">
        <mat-icon class="sidenav-icon" *ngIf="menuItem.icon">{{ menuItem.icon }}</mat-icon>
        {{ menuItem.name }}
      </span>
    </span>

    <mat-nav-list class="sidenav-nested-menu" [hidden]="!menuItem.showMenu">
      <span *ngFor="let item of menuItem.items">
        <portal-side-nav-menu
          #nestedSideNavMenu
          class="nested-level-{{ incrementNestLevel() }}"
          [nestLevel]="incrementNestLevel()"
          [navMenuItems]="[item]"
          [currentRouterLink]="currentRouterLink"
          (updateEvent)="updateCurrentLink($event)"
        >
        </portal-side-nav-menu>
      </span>
    </mat-nav-list>
  </span>
</span>

<a
  *ngIf="nestLevel === 0"
  mat-list-item
  (click)="navToProfile()"
  class="side-menu-option"
  style="--left-pad: {{ getLeftPadValue() }}"
>
    Profile
  <mat-icon class="profile-open-icon">open_in_new</mat-icon>
</a>