<mat-form-field
  [appearance]="appearance"
  class="chip-form-field"
>
  <div class="chiplist-left-side">
    <mat-chip-list
      *ngIf="disableDefaultChiplistEditing(element)"
      multiple
      #chipList
      [required]="required"
      (click)="openChiplistExpandDialog()"
      class="clickable"
    >
      <mat-chip
        *ngFor="let value of getChiplistValuesToDisplayInInputList(element)"
        [selectable]="filterChipOptions.selectable"
        removable="false"
        (removed)="onRemoveChip(value)"
        style="--color: {{ chiplistInput.getChipColor(value) }}"
        [matTooltip]="getChipTooltipText(value)"
        [ngClass]="{ 'clickable': disableDefaultChiplistEditing(element) }"
      >
        {{ chiplistInput.getDisplayValue(value, chiplistInput) }}
      </mat-chip>
      <span
        class="more-chips"
        [matTooltip]="getHiddenChipsTooltipText()"
        [ngClass]="{ 'clickable': disableDefaultChiplistEditing(element) }"
      >
        ... <span class="more-chips-text">({{ getNumberOfHiddenChips() }} additional)</span>
      </span>
    </mat-chip-list>

    <span *ngIf="!disableDefaultChiplistEditing(element)">
      <mat-chip-list  multiple #chipList [required]="required">
        <mat-chip
          *ngFor="let value of getChiplistValuesToDisplayInInputList(element)"
          [selectable]="filterChipOptions.selectable"
          [removable]="isChipRemovable"
          (removed)="onRemoveChip(value)"
          style="--color: {{ chiplistInput.getChipColor(value) }}"
          [matTooltip]="chiplistInput.getTooltip(value, chiplistInput)"
        >
          {{ chiplistInput.getDisplayValue(value, chiplistInput) }}
          <mat-icon matChipRemove *ngIf="isChipRemovable">cancel</mat-icon>
        </mat-chip>
        <input
          #mainInputElement
          matInput
          #trigger="matAutocompleteTrigger"
          [placeholder]="placeholder"
          [formControl]="chiplistInput.formControl"
          [matChipInputFor]="chipList"
          [matAutocomplete]="auto"
          [matChipInputSeparatorKeyCodes]="filterChipOptions.separatorKeysCodes"
          [matChipInputAddOnBlur]="filterChipOptions.addOnBlur"
          (matChipInputTokenEnd)="delayAndAddChipOnInputEvent($event, element, chiplistInput)"
          [matTooltip]="getInputTooltip(chiplistInput)"
          [hidden]="!chiplistInput.isEditable || chiplistInput.disableField(element)"
          (keyup.enter)="onChiplistKeyTab($event.target.value, $event.target.id, chiplistInput)"
          (keydown.tab)="onChiplistKeyTab($event.target.value, $event.target.id, chiplistInput)"
          autocomplete="off"
        />
      </mat-chip-list>
      <mat-autocomplete
        #auto="matAutocomplete"
        (optionSelected)="
        onChiplistAutocompleteSelection($event.option.value, mainInputElement, chiplistInput, element)
        "
      >
        <span *ngIf="!chiplistInput.hasMultiAutocomplete || (!!chiplistInput.hasMultiAutocomplete && !chiplistInput.isFirstMultiAutocompleteOptionSelected)">
          <span *ngFor="let option of getSortedFilteredAutocompleteValues(chiplistInput.filteredValues | async)">
            <mat-option
              [value]="chiplistInput.getDisplayValue(option, chiplistInput)"
              *ngIf="!isOptionAlreadySelected(element, chiplistInput, chiplistInput.getDisplayValue(option, chiplistInput)) && chiplistInput.isValidEntry(option, element) && !removeSelfValue(element, chiplistInput, chiplistInput.getDisplayValue(option, chiplistInput))"
              [matTooltip]="chiplistInput.getOptionTooltip(option, element)"
            >
              {{ chiplistInput.getDisplayValue(option, chiplistInput) }}
            </mat-option>
          </span>
        </span>
        <span *ngIf="!!chiplistInput.hasMultiAutocomplete && !!chiplistInput.isFirstMultiAutocompleteOptionSelected">
          <span *ngFor="let option of chiplistInput.getSecondaryFilteredValues(chiplistInput, mainInputElement.value) | async">
            <mat-option
              [value]="chiplistInput.getOptionValue(option)"
              *ngIf="!isOptionAlreadySelected(element, chiplistInput, chiplistInput.getDisplayValue(option, chiplistInput)) && chiplistInput.isValidEntry(option, element)"
              [matTooltip]="chiplistInput.getOptionTooltip(option, element)"
            >
              {{ chiplistInput.getDisplayValue(option, chiplistInput) }}
            </mat-option>
          </span>
        </span>
      </mat-autocomplete>
    </span>
  </div>

  <div class="chiplist-right-side">
      <button
        mat-icon-button
        [matMenuTriggerFor]="chiplistDialogMenu"
        aria-label="Icon-button with a menu to choose an action for the selected row"
        matTooltip="Click to view additional options"
        class="open-chiplist-dialog-icon"
        (click)="preventOpeningAutocompleteDropdownOnDialogConfigButtonClick($event)"
      >
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #chiplistDialogMenu="matMenu">
        <button
          mat-menu-item
          (click)="openChiplistExpandDialog()"
          matTooltip="Click to open the advanced configuration dialog"
        >
          <mat-icon>open_in_browser</mat-icon>
          <span>Advanced Configuration</span>
        </button>
      </mat-menu>
  </div>
</mat-form-field>