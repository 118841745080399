<portal-page-info
  pageTitle="New Sub-Organisation"
  [pageDescriptiveText]="pageDescriptiveText"
  [productGuideLink]="productGuideLink"
>
</portal-page-info>

<div *ngIf="hasUsersPermissions !== undefined && !hasUsersPermissions">
  <portal-no-permissions></portal-no-permissions>
</div>

<div 
  *ngIf="hasUsersPermissions && !!allForms" 
  class="stepper-container"
>
  <mat-vertical-stepper
    linear
    #subOrgStepper
  >
    <mat-step *ngIf="orgCanCreateNewCustomerSubOrg" [stepControl]="subOrgTypeFormGroup">
      <ng-template matStepLabel>Sub-Organisation type</ng-template>
      <form
        [formGroup]="subOrgTypeFormGroup"
        class="stepper-form"
        spellcheck="false"
        autocomplete="off"
      >
        <label
          id="sub-org-type-radio-group-label"
          class="radio-button-group-label"
        >
          My new sub-organisation:
        </label>
        <mat-radio-group
          aria-labelledby="sub-organisation type selection radio buttons"
          class="sub-org-type-radio-group"
          formControlName="orgType"
        >
          <mat-radio-button
            class="sub-org-type-radio-button full-line-radio-button"
            *ngFor="let option of subOrgTypeOptions"
            [value]="option.value"
            (change)="onSubOrgTypeOptionChange(option.value)"
          >
            {{ option.displayValue }}
          </mat-radio-button>
        </mat-radio-group>
      </form>

      <div class="stepper-nav-buttons">
        <button mat-button matStepperNext>Next</button>
      </div>
    </mat-step>

    <mat-step [stepControl]="orgNameForm">
      <ng-template matStepLabel>Sub-Organisation name</ng-template>
      <form
        [formGroup]="orgNameForm"
        class="stepper-form"
        spellcheck="false"
        autocomplete="off"
      >
        <mat-form-field [hintLabel]="getOrgNameHintLabel()">
          <mat-label>Organisation/Company/Account Name</mat-label>
          <input
            matInput
            id="org-name"
            formControlName="orgName"
            required (keyup.enter)="keyTabManager.keyTab($event.target.id)"
          >
          <mat-error>A valid organisation name is required</mat-error>
        </mat-form-field>
      </form>

      <div class="stepper-nav-buttons">
        <button *ngIf="orgCanCreateNewCustomerSubOrg" mat-button matStepperPrevious>Back</button>
        <button mat-button matStepperNext>Next</button>
      </div>
    </mat-step>

    <mat-step *ngIf="userSetsDnsDomain()" [completed]="isValidSubdomain">
      <ng-template matStepLabel>DNS domain</ng-template>
      <form
        [formGroup]="subdomainOwnForm"
        class="stepper-form"
        spellcheck="false"
        autocomplete="off"
      >
        <mat-form-field [hintLabel]="subdomainHintLabel">
          <mat-label>(sub)domain (e.g. cloud.example.com)</mat-label>
          <input
            #domainNameInput
            matInput
            id="domain-own-name"
            formControlName="domain"
            required
            (keyup.enter)="keyTabManager.keyTab($event.target.id)"
          >
          <mat-error *ngIf="!subdomainOwnForm.get('domain').errors?.cnameNotResolved">
            A valid domain is required
          </mat-error>
          <mat-error *ngIf="subdomainOwnForm.get('domain').errors?.cnameNotResolved">
            Unable to resolve CNAME. Did you assign the appropriate CNAME? 
            Is your DNS split-horizon? Was it setup as a wildcard?
          </mat-error>
        </mat-form-field>
        <button
          style="margin-left: 20px;"
          mat-raised-button color="primary"
          (click)='verifySubDomain()'
        >
          Validate
        </button>
        <div class="validation-stepper">
          <lib-stepper-progress-bar
            [isVertical]="isVertical"
            [controller]="progressStepper"
            [steps]="steps"
          >
          </lib-stepper-progress-bar>
        </div>
        <div class="own-domain-instructions">
          <br>
          <portal-product-guide-link
            productGuidePreText="NOTE: To use your own (sub)domain name,
            you will need to assign a "
            productGuideLink="https://www.agilicus.com/about-dns-cname/"
            productGuideLinkText="CNAME"
            [productGuidePostText]="productGuidePostText"
          >
          </portal-product-guide-link>

          <p>
            We recommend you create a CNAME for *.cloud.MYCOMPANY.com, this means that your users will always
            access https://&lt;appname&gt;.cloud.MYCOMPANY.com
          </p>

          <portal-product-guide-link
            productGuidePreText="NOTE: If you run split-horizon DNS (e.g. 2 DNS servers, 1 internal, 1 external), make sure to "
            productGuideLink="https://www.agilicus.com/anyx-guide/signup/#h-step-2-domain-selection"
            productGuideLinkText="configure"
            productGuidePostText="both."
          >
          </portal-product-guide-link>

          <portal-product-guide-link
            productGuidePreText="NOTE: Help on selecting a (sub) "
            productGuideLink="https://www.agilicus.com/anyx-guide/theory-of-operation-cname-domain/"
            productGuideLinkText="domain and DNS setup"
          >
          </portal-product-guide-link>
        </div>
      </form>

      <div class="stepper-nav-buttons">
        <button mat-button matStepperPrevious>Back</button>
        <button
          mat-button
          matStepperNext
          [matTooltip]="getSubdomainNextButtonTooltip()"
        >
          Next
        </button>
      </div>
    </mat-step>

    <mat-step [completed]="isStepperComplete()">
      <ng-template matStepLabel>Apply</ng-template>
      <p class="descriptive-text">Your Sub-Organisation setup is complete. Please review the details below. Make any corrections needed above.</p>

      <div
        class="summary-container"
      >
        <h4>Summary:</h4>
        <p>Organisation name: {{ getOrgNameFromForm() }}</p>
        <p *ngIf="userSetsDnsDomain()">DNS domain: {{ getOwnSubdomainFormValue() }}</p>
      </div>

      <portal-apply-app-model
        [appModelSubmissionProgressBarController]="appModelSubmissionProgressBarController"
        [appModelStatus]="modelStatus"
        [stepperType]="stepperType"
        [progressBarInfoText]="getCustomProgressBarEstimateText()"
        (submitModel)="onApply()"
      >
      </portal-apply-app-model>

      <div class="stepper-nav-buttons">
        <button mat-button matStepperPrevious>Back</button>
      </div>
    </mat-step>

    <mat-step>
      <ng-template matStepLabel>Done</ng-template>
    </mat-step>
  </mat-vertical-stepper>
</div>





